<template>
    <template v-if="content">
        <router-link
            class="image-big-box spacing-bottom-xs"
            :to="'/' + cont.element.url"
            v-for="(cont, c) in content"
            :key="c"
            v-ripple
        >
            <h2>
                <span
                    v-if="cont?.element?.headline"
                    class="secondary-font mt-1"
                >
                    {{ cont.element.headline }}
                </span>
                <span v-if="cont?.element?.subline">
                    {{ cont.element.subline }}
                </span>
            </h2>

            <custom-image
                v-if="cont?.element?.imagesTeaser"
                :image="cont.element?.imagesTeaser[0]"
                cover
                :aspect-ratio="aspectRatio"
            />
            <custom-image
                v-if="cont?.element?.imageTeaser"
                :image="cont.element?.imageTeaser"
                cover
                :aspect-ratio="aspectRatio"
            />

            <div class="button-container">
                <custom-button>
                    {{ cont.element.linkText }}
                    <img
                        class="custom-icon"
                        :src="api + '/frontend-icons/arrow-right.svg'"
                        alt="weiter"
                    />
                </custom-button>
            </div>
        </router-link>
    </template>
    <template v-else-if="item">
        <router-link class="image-big-box" :to="'/' + item.url" v-ripple>
            <h2>
                <span v-if="item?.headline" class="secondary-font mt-1">
                    {{ item.headline }}</span
                >
                <span v-if="item?.subline"> {{ item.subline }}</span>
            </h2>

            <custom-image
                v-if="item?.imagesTeaser"
                :image="item.imagesTeaser[0]"
                cover
                :aspect-ratio="aspectRatio"
            />
            <custom-image
                v-if="item?.imageTeaser"
                :image="item.imageTeaser"
                cover
                :aspect-ratio="aspectRatio"
            />

            <div class="button-container">
                <custom-button>
                    {{ item.linkText }}
                    <img
                        class="custom-icon"
                        :src="api + '/frontend-icons/arrow-right.svg'"
                        alt="weiter"
                    />
                </custom-button>
            </div>
        </router-link>
    </template>
</template>

<script setup>
import { computed, defineProps, ref } from "vue";
import CustomButton from "@/components/reuseables/customButton.vue";
import CustomImage from "@/components/reuseables/customImage.vue";
import { vuetify } from "@/plugins/vuetify";

const props = defineProps({
    data: {
        type: Object,
        required: false,
    },
    content: {
        type: Array,
        required: false,
    },
    items: {
        type: Array,
        required: false,
    },
    item: {
        type: Object,
        required: false,
    },
    listing: {
        type: Object,
        required: false,
    },
    listingData: {
        type: Object,
        required: false,
    },
});

const api = process.env.VUE_APP_API_URL;

const aspectRatio = computed(() => {
    const display = ref(vuetify.display);

    let aspectRatio = {
        base: props.listingData.baseAR || "16/9",
        sm: props.listingData.smallAR || "16/9",
        md: props.listingData.mediumAR || "16/9",
        lg: props.listingData.largeAR || "16/9",
        xl: props.listingData.extraLargeAR || "16/9",
        xxl: props.listingData.xxlAR || "16/9",
    };

    for (const size of ["xxl", "xl", "lg", "md", "sm"]) {
        if (display.value[size]) {
            return aspectRatio[size];
        }
    }

    return aspectRatio.base;
});
</script>

<style lang="scss" scoped>
.image-big-box {
    display: block;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    text-decoration: none;
    color: white;
    h2 {
        color: black;
        display: flex;
        flex-direction: column;
    }
    .button-container {
        display: flex;
        justify-content: right;
    }
    //:deep(.v-img) {
    //    min-height: 33vh;
    //    max-height: 50vh;
    //}
}
</style>
