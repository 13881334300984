function replaceImageThumbnail(source, to) {
    return source.replace("360", to);
}

function defineThumbnail(imagePath) {
    //GET WITH OF THE IMAGE
    let w = window.innerWidth;

    //DEFINE THUMBNAIL
    let wantedSize = "";
    if (w >= 1320) wantedSize = "1920";
    else if (w >= 800 && w < 1320) wantedSize = "1280";
    else if (w >= 480 && w < 800) wantedSize = "720";
    else if (w < 480) wantedSize = "360";

    return (
        process.env.VUE_APP_API_URL +
        replaceImageThumbnail(imagePath, wantedSize)
    );
}

export function getImagesForLightBox(images) {
    let array = [];
    if (images?.length > 0) {
        images.forEach((e) => {
            if (e.element) {
                array.push(defineThumbnail(e.element.fullpath));
            } else if (e.image) {
                array.push(defineThumbnail(e.image.fullpath));
            }
        });
    }
    return array;
}

export function trimmedLink(link) {
    return link.split("/").slice(2).join("/");
}

export function changeDateToLanguage(date, language) {
    const newDate = new Date(date);
    const options = { day: "numeric", month: "long", year: "numeric" };

    return newDate.toLocaleDateString(language, options);
}
