import gql from "graphql-tag";

import generalImages from "../fragments/general/generalImages.graphql";
import generalSeo from "../fragments/general/generalSeo.graphql";
import generalLinks from "../fragments/general/generalLinks.graphql";

export const GET_LESSON = gql`
    ${generalImages}
    ${generalSeo}
    ${generalLinks}

    query ($filter: String) {
        getLessonListing(first: 1, filter: $filter, defaultLanguage: "de") {
            edges {
                node {
                    name
                    description
                    information
                    memberStart
                    memberEnd
                    weekdays
                    slug
                    displayType
                    links {
                        ...generalLinks
                    }
                    detailLinks {
                        ...generalLinks
                    }
                    lessonTrainer {
                        ... on object_trainer {
                            firstname
                            lastname
                        }
                    }
                    price {
                        unit {
                            abbreviation
                        }
                        value
                    }
                    lessonDates {
                        ... on object_lessonDateGroup {
                            title
                            information
                            month
                            year
                            lessons {
                                ... on object_lessonDate {
                                    date_Start
                                    date_End
                                }
                            }
                        }
                    }
                    imageHeader {
                        ... on object_header {
                            images {
                                ...generalImages
                            }
                        }
                    }
                    seo {
                        ...generalSeo
                    }
                }
            }
        }
    }
`;
