<template>
    <custom-button class="mb-12" :to="'/news'">
        <img
            class="custom-icon small ml-0 mr-2"
            :src="api + '/frontend-icons/arrow-left.svg'"
            alt="zurück"
        />
        Zurück zur Newsübersicht
    </custom-button>
</template>

<script setup>
import CustomButton from "@/components/reuseables/customButton.vue";
const api = process.env.VUE_APP_API_URL;
</script>

<style scoped>
</style>
