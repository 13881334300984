<template>
    <object_header
        v-if="data?.imageHeader && data?.imageHeader[0]"
        :data="{
            headline: 'Bahn',
            subHeadline: data.number.toString(),
            images: [data.imageHeader[0]],
            sizeOfHero: 'small',
            subAlign: 'text-center',
        }"
        key="header"
    />
    <transition-group name="fade-scale" appear>
        <v-container v-if="data.id" class="mt-12">
            <v-row>
                <v-col cols="12" sm="4" md="4" lg="5" xl="6" class="image-hole">
                    <hole-navigation
                        :desktop="false"
                        :mobile="true"
                        :data="data"
                        class="mb-8"
                    />

                    <mobile-information
                        v-if="windowSize.x < 600"
                        :par="data.par"
                        :handicap="data.handicap"
                        :male-easy="data.maleEasy"
                        :male-hard="data.maleHard"
                        :female-easy="data.femaleEasy"
                        :female-hard="data.femaleHard"
                    />

                    <custom-image
                        v-if="data.imageHole"
                        :image="data.imageHole[0]"
                        max-height="66vh"
                        width="100%"
                        container-height="auto"
                        contain
                        v-ripple
                        @click="visibleRef = !visibleRef"
                        style="color: white"
                    />
                    <vue-easy-lightbox
                        v-if="data.imageHole"
                        :visible="visibleRef"
                        :imgs="imgsRef"
                        :index="indexRef"
                        @hide="onHide"
                        class="lightbox-light"
                        rotate-disabled
                        zoom-disabled
                        pinch-disabled
                        move-disabled
                    />
                </v-col>
                <v-col
                    cols="12"
                    sm="8"
                    md="8"
                    lg="7"
                    xl="6"
                    class="information-container"
                >
                    <hole-navigation
                        :desktop="true"
                        :mobile="false"
                        :data="data"
                        class="mb-8"
                    />

                    <div class="tips">
                        <div class="headline-container mb-8">
                            Spieltipps für Loch {{ data.number }}
                        </div>
                        <p v-if="data.tips" class="secondary-font">
                            {{ data.tips }}
                        </p>

                        <div
                            v-if="windowSize.x >= 600"
                            class="par-and-handicap"
                        >
                            <div v-if="data.par" class="par">
                                <span>{{ data.par }}</span>
                                par
                            </div>
                            <div class="handicap" v-if="data.handicap">
                                <span>{{ data.handicap }}</span>
                                handicap
                            </div>
                        </div>
                    </div>
                    <div v-if="windowSize.x >= 600" class="distance">
                        <div class="headline-container mb-0">Entfernungen</div>
                        <div v-if="data.maleEasy" class="male-easy">
                            <v-icon icon="mdi-gender-male" />
                            <span class="gender"> Herren</span>
                            -
                            <number
                                :to="data.maleEasy"
                                :duration="2"
                                easing="Power1.easeOut"
                            />
                            Meter
                        </div>
                        <div v-if="data.maleHard" class="male-hard">
                            <v-icon icon="mdi-gender-male" />

                            <span class="gender"> Herren </span>
                            -
                            <number
                                :to="data.maleHard"
                                :duration="2"
                                easing="Power1.easeOut"
                            />
                            Meter
                        </div>
                        <div v-if="data.femaleEasy" class="female-easy">
                            <v-icon icon="mdi-gender-female" />
                            <span class="gender"> Damen </span>
                            -
                            <number
                                :to="data.femaleEasy"
                                :duration="2"
                                easing="Power1.easeOut"
                            />
                            Meter
                        </div>
                        <div v-if="data.maleHard" class="female-hard">
                            <v-icon icon="mdi-gender-female" />

                            <span class="gender"> Damen </span>
                            -

                            <number
                                :to="data.femaleHard"
                                :duration="2"
                                easing="Power1.easeOut"
                            />
                            Meter
                        </div>
                    </div>
                    <div
                        class="partner"
                        v-if="data?.partner?.logo"
                        :data-id="data.partner.id"
                    >
                        <div class="headline-container">
                            Dieses Loch wird Ihnen Präsentiert von
                        </div>
                        <custom-image
                            :image="data.partner.logo[0]"
                            width="100%"
                            max-height="8rem"
                        />
                        <p v-html="data.partner.holeText" />
                    </div>
                    <hole-navigation
                        :desktop="true"
                        :mobile="true"
                        :data="data"
                    />
                </v-col>
            </v-row>
        </v-container>

        <div class="image-gallery-container" v-if="data.imageGallery">
            <image-swiper :data="data.imageGallery" />
        </div>
    </transition-group>

    <custom-loading v-if="loading" />
    <page-not-found v-if="!loading && !data.id" />
</template>

<script setup>
import { apolloProvider } from "@/plugins/apollo";
import { GET_HOLE } from "@/graphql/querie/pages/hole";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import CustomImage from "@/components/reuseables/customImage.vue";
import Object_header from "@/components/object_header.vue";
import VueEasyLightbox from "vue-easy-lightbox";
import { useHead } from "unhead";
import PageNotFound from "@/pages/pageNotFound.vue";
import { watch } from "vue";
import HoleNavigation from "@/pages/pageHole/holeNavigation.vue";
import ImageSwiper from "@/components/listings/imageSwiper.vue";
import MobileInformation from "@/pages/pageHole/mobileInformation.vue";
import { getImagesForLightBox } from "@/mixins/global/globalMethods";
import CustomLoading from "@/components/reuseables/loading.vue";

const route = useRoute(),
    data = ref({}),
    onHide = ref(),
    visibleRef = ref(),
    indexRef = ref(),
    imgsRef = ref(),
    loading = ref(true),
    windowSize = ref({ x: 0, y: 0 });

watch(() => route.params.fullpath, loadHole);

function onResize() {
    windowSize.value = { x: window.innerWidth, y: window.innerHeight };
}

function setPageMetadata() {
    if (data.value.seo) {
        useHead({
            title: "Bahn | " + data.value.seo[0].title,
            link: [
                {
                    name: "canonical",
                    content: data.value.seo[0].canonical,
                },
            ],
            meta: [
                {
                    name: "description",
                    content: data.value.seo[0].description,
                },
                {
                    name: "keywords",
                    content: data.value.seo[0].keywords,
                },
                {
                    robots: "robots",
                    content: data.value.seo[0].robots || "index, follow",
                },
            ],
        });
    }
}

function loadHole() {
    data.value = {};
    loading.value = true;
    apolloProvider.defaultClient
        .query({
            fetchPolicy: "no-cache",
            query: GET_HOLE,
            variables: {
                filter: '{"url": {"$like" :"' + route.params.fullpath + '"}}',
                lang: "de",
            },
        })
        .then((response) => {
            loading.value = false;

            if (response.data.getHoleListing.edges[0].node) {
                data.value = response.data.getHoleListing.edges[0].node;

                onHide.value = () => (visibleRef.value = false);
                visibleRef.value = false;
                indexRef.value = 0;

                if (data.value.imageHole) {
                    imgsRef.value = getImagesForLightBox(data.value?.imageHole);
                }

                setPageMetadata();
            } else {
                data.value = {};
            }
        });
}

onMounted(() => {
    loadHole();
    onResize();
});
</script>

<style lang="scss" scoped>
.image-container {
    &:deep(.v-img) {
        position: sticky;
        top: 8rem;
    }
}

.v-container {
    margin-top: 0;

    @media (min-width: 961px) {
        margin-top: 4rem;
    }
    @media (min-width: 1280px) {
        margin-top: 6rem;
    }
}

.tips {
    margin-bottom: 2rem;
    .par-and-handicap {
        display: flex;
        align-items: center;
        @media (max-width: 599px) {
            justify-content: center;
        }
        .par,
        .handicap {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 2rem;
            span {
                font-weight: bold;
                font-size: 4.375rem;
                line-height: normal;
                @media (max-width: 650px) {
                    font-size: 3.9rem;
                }
                @media (max-width: 439px) {
                    font-size: 2.95rem;
                }
            }
        }
    }
}
.distance {
    margin-bottom: 6rem;
    [class*="male"] {
        padding: 1rem 0.5rem;
        font-size: 2.5rem;
    }
    i {
        @media (min-width: 1280px) {
            display: none;
        }
    }
    span.gender {
        @media (max-width: 1280px) {
            display: none;
        }
    }
    .male-easy {
        background-color: white;
    }
    .male-hard {
        background-color: rgba(254, 223, 51, 0.3);
    }
    .female-easy {
        background-color: rgba(0, 55, 129, 0.3);
    }
    .female-hard {
        background-color: rgba(190, 2, 2, 0.3);
    }
}

.partner {
    border-bottom: 2px solid black;
    padding-bottom: 1rem;
    p {
        margin-top: 1rem;
    }
}

.image-gallery-container {
    padding: 4rem;
    background-color: var(--light-color);
    .v-window {
        height: 100% !important;
        .v-row {
            gap: 1.5rem;
            .v-col {
                color: var(--medium-color);
                cursor: pointer;
            }
        }
    }
}
</style>
