<template>
    <object_header
        v-if="data.imageHeader"
        :data="{
            headline: 'Kurs',
            subHeadline: data.name,
            images: [data.imageHeader.images[0]],
            sizeOfHero: 'small'
        }"
    />
    <transition-group name="fade-scale" appear>
        <v-container v-if="!loading" class="page-lesson">
            <back-button :buttons="data.links"/>

            <v-row>
                <v-col cols="12" lg="7" md="7" sm="12" xl="8">
                    <div
                        v-if="!data.imageHeader"
                        class="headline-container mt-0 mb-6"
                    >
                        <h2 class="h3">{{ data.name }}</h2>
                    </div>

                    <headline-container mb="xs">
                        Kursbeschreibung
                    </headline-container>

                    <p v-html="data.description"></p>

                    <component
                        :is="components['display' + data.displayType]"
                        :dates="sortedDates"
                    />
                </v-col>

                <v-col cols="12" lg="5" md="5" sm="12" xl="4">
                    <headline-container mb="xs"> Details</headline-container>

                    <v-table>
                        <tr>
                            <td>Teilnehmerzahl:</td>
                            <td
                                v-if="data.memberStart"
                                class="font-weight-bold"
                            >
                                min. {{ data.memberStart }} bis zu max.
                                {{ data.memberEnd }}
                            </td>
                            <td v-else class="font-weight-bold">
                                bix zu max. {{ data.memberEnd }}
                            </td>
                        </tr>
                        <tr v-if="data.lessonTrainer">
                            <td>Trainer:</td>
                            <td>
                                <span
                                    v-for="(
                                        trainer, index
                                    ) in data.lessonTrainer"
                                    :key="index"
                                    class="trainer font-weight-bold"
                                >
                                    {{ trainer.firstname }}
                                    {{ trainer.lastname }}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>Preis:</td>
                            <td class="font-weight-bold">
                                {{
                                    data.price.value.toLocaleString("de-DE", {
                                        minimumFractionDigits: 2,
                                    })
                                }}
                                {{ data.price.unit.abbreviation }}
                            </td>
                        </tr>
                    </v-table>

                    <template v-if="data.detailLinks && data.detailLinks.length > 0">
                        <custom-button v-for="(link, key) in data.detailLinks" :general-link="link" :key="key"
                                       class="cta-btn w-100 mt-8"/>
                    </template>

                    <div class="course-information" v-html="data.information"/>

                </v-col>
            </v-row>

            <back-button :buttons="data.links"/>
        </v-container>
    </transition-group>
    <custom-loading v-if="loading"/>

    <page-not-found v-if="!loading && !data.name"/>
</template>

<script setup>
import {apolloProvider} from "@/plugins/apollo";
import {GET_LESSON} from "@/graphql/querie/pages/lesson";
import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import PageNotFound from "@/pages/pageNotFound.vue";
import Object_header from "@/components/object_header.vue";
import displayAccordion from "@/pages/PageLesson/displayAccordion.vue";
import displayList from "@/pages/PageLesson/displayList.vue";
import HeadlineContainer from "@/components/reuseables/headlineContainer.vue";
import BackButton from "@/pages/PageLesson/backButton.vue";
import {useHead} from "unhead";
import CustomLoading from "@/components/reuseables/loading.vue";
import CustomButton from "@/components/reuseables/customButton.vue";

const components = {displayAccordion, displayList},
    route = useRoute(),
    data = ref({}),
    onHide = ref(),
    visibleRef = ref(),
    indexRef = ref(),
    loading = ref(true),
    sortedDates = ref({});

function mapDates(dates) {
    sortedDates.value = {};

    const months = {
        "01": "Januar",
        "02": "Februar",
        "03": "März",
        "04": "April",
        "05": "Mai",
        "06": "Juni",
        "07": "Juli",
        "08": "August",
        "09": "September",
        10: "Oktober",
        11: "November",
        12: "Dezember",
    };

    dates.forEach((entry) => {
        const month = months[entry.month],
            year = entry.year;

        if (!sortedDates.value[year]) {
            sortedDates.value[year] = {};
        }

        if (!sortedDates.value[year][month]) {
            sortedDates.value[year][month] = [];
        }

        if (entry.lessons) {
            entry.lessons.forEach((lesson, key) => {
                entry.lessons[key] = {
                    date: lesson.date_Start.split(" ")[0],
                    start: lesson.date_Start.split(" ")[1],
                    end: lesson.date_End.split(" ")[1],
                };
            });
        }

        sortedDates.value[year][month].push(entry);
    });
}

function loadLesson() {
    data.value = {};
    loading.value = true;
    apolloProvider.defaultClient
        .query({
            fetchPolicy: "no-cache",
            query: GET_LESSON,
            variables: {
                filter: '{"slug": {"$like" :"' + route.params.slug + '"}}',
                lang: "de",
            },
        })
        .then((response) => {
            loading.value = false;

            if (response.data.getLessonListing.edges[0].node) {
                data.value = response.data.getLessonListing.edges[0].node;

                onHide.value = () => (visibleRef.value = false);
                visibleRef.value = false;
                indexRef.value = 0;
                mapDates(data.value.lessonDates);
                setPageMetadata();
            } else {
                data.value = {};
            }
        });
}

function setPageMetadata() {
    if (data.value.seo) {
        useHead({
            title: "Kurs | " + data.value.seo[0].title,
            link: [
                {
                    name: "canonical",
                    content: data.value.seo[0].canonical,
                },
            ],
            meta: [
                {
                    name: "description",
                    content: data.value.seo[0].description,
                },
                {
                    name: "keywords",
                    content: data.value.seo[0].keywords,
                },
                {
                    robots: "robots",
                    content: data.value.seo[0].robots || "index, follow",
                },
            ],
        });
    }
}

onMounted(() => {
    loadLesson();
});
</script>

<style lang="scss">
.page-lesson {
    margin-bottom: 12rem;
}

table tr td {
    font-size: 16px;
    vertical-align: baseline;

    &:first-child {
        word-break: keep-all;
    }

    &:nth-child(2) {
        text-align: right;
    }
}

span.trainer:not(:last-of-type)::after {
    content: ", ";
}

.course-information {
    margin-top: 2rem;

    ul {
        padding-left: 1.25rem;
    }
}
</style>
