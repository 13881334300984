<template>
    <div class="navLinks" :class="[{ desktop, mobile }, props.class]">
        <router-link
            v-if="data?.holeBefore?.url"
            :to="{
                page: 'hole',
                params: { fullpath: data.holeBefore.url },
            }"
            v-ripple
        >
            <img
                class="custom-icon"
                :src="api + '/frontend-icons/arrow-left.svg'"
                alt="zurück"
                width="30"
                height="30"
            />
            Bahn {{ data.holeBefore.number }}
        </router-link>
        <div class="overviewLinks">
            <router-link
                v-if="data?.holeOverview?.url"
                :to="`/${data.holeOverview.url}`"
                >Bahnübersicht</router-link
            >
            <router-link
                v-if="data?.courseOverview?.url"
                :to="`/${data.courseOverview.url}`"
                >Platzübersicht</router-link
            >
        </div>
        <router-link
            v-if="data?.holeAfter?.url"
            :to="{ page: 'hole', params: { fullpath: data.holeAfter.url } }"
            v-ripple
        >
            Bahn {{ data.holeAfter.number }}
            <img
                class="custom-icon"
                :src="api + '/frontend-icons/arrow-right.svg'"
                alt="weiter"
                width="30"
                height="30"
            />
        </router-link>
    </div>
</template>


<script setup>
import {defineProps} from 'vue'
const api = process.env.VUE_APP_API_URL;

const props = defineProps({
    data: Object,
    desktop: Boolean,
    mobile: Boolean,
    class: String
})

</script>

<style lang="scss" scoped>

.navLinks {
    display: none;
    justify-content: space-between;
    a {
        line-height: 1.8rem;
        display: flex;
        gap: .3rem;
        text-decoration: none;
        color: black;
    }
    .custom-icon {
        width: 1.8rem;
        height: 1.8rem;
        margin: 0;
    }
    .overviewLinks {
        display: flex;
        gap: 1rem;
        justify-content: center;
        a {
            text-decoration: underline;
        }
    }
    @media (max-width: 960px) {
        .overviewLinks {
            flex-direction: column;
        }
    }
    @media (max-width: 599px) {
        &.mobile {
            display: flex;
        }
        a {
            line-height: 1.2rem;
        }
        .custom-icon {
            width: 1.2rem;
            height: 1.2rem;
        }
    }
    @media (min-width: 600px) {
        &.desktop {
            display: flex;
        }
    }
}
</style>