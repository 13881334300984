<template>
    <field-headline
        :label="label"
        :additionalLabel="data.additionalLabel"
        :annotation="annotation"
    />

    <v-select
        v-model="input"
        :items="props.options"
        :item-title="(item) => item.val"
        :item-value="(item) => item.key"
        :rules="checkFormFieldRules(data)"
        return-object
        variant="outlined"
        :clearable="true"
    />
</template>

<script setup>
import { onMounted, ref, watch, defineProps, defineEmits } from "vue";
import { checkFormFieldRules } from "@/plugins/form/validation";
import FieldHeadline from "@/components/form/fields/fieldHeadline.vue";

const input = ref({ key: "", val: "" }),
    emit = defineEmits(["updated"]),
    props = defineProps({
        label: {
            type: String,
            required: true,
        },
        value: {
            type: Object,
            required: false,
            default() {
                return {
                    key: "",
                    val: false,
                };
            },
        },
        options: {
            type: Array,
            required: true,
        },
        data: {
            type: Object,
            required: false,
        },
        name: {
            type: String,
            required: false,
        },
        annotation: {
            type: Array,
            required: false,
        },
    });
watch(input, (newValue) => emit("updated", props.name, newValue));

onMounted(() => {
    input.value = props.value;
});
</script>
