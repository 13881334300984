import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { vuetify } from "./plugins/vuetify";
import { apolloProvider } from "./plugins/apollo";
import VueNumber from "vue-number-animation";
import VueMobileDetection from "vue-mobile-detection";
import VueEasyLightbox from "vue-easy-lightbox";

const app = createApp(App);

app.provide("apollo", apolloProvider.defaultClient);

import { createHead } from "unhead";

// eslint-disable-next-line
const head = createHead();

import object_header from "@/components/object_header.vue";
import object_teaser from "@/components/object_Teaser.vue";

import "./assets/fonts/fonts.css";

app.component("object_teaser", object_teaser);
app.component("object_header", object_header);
app.component("vue-number", VueNumber);

// GLOBAL METHODS
// import { getArrayOfFullpaths } from "@/mixins/global/globalComponents";
// app.config.globalProperties.$getArrayOfFullpaths = getArrayOfFullpaths;

import DOMPurify from "dompurify";
function sanitizeHtml(html) {
    return DOMPurify.sanitize(html);
}
app.config.globalProperties.$sanitizeHtml = sanitizeHtml;

app
    // .use(pinia)
    .use(apolloProvider)
    .use(router)
    .use(vuetify)
    .use(VueEasyLightbox)
    .use(VueNumber)
    .use(VueMobileDetection)
    .mount("#app");
