<template>
    <v-row v-if="data">
        <v-col cols="12" md="6" lg="7" xl="8">
            <headline-container mb="xs">
                <h2 class="h3">Platzübersicht</h2>
            </headline-container>
            <p
                v-if="data.text"
                v-html="data.text"
                class="secondary-font mb-12"
            />
            <div
                v-if="
                    data.bookingLinks && data.bookingLinks[0]?.dataObject?.url
                "
                class="button-container"
            >
                <custom-button
                    class="cta-btn my-10"
                    :to="data.bookingLinks[0].dataObject.url"
                >
                    {{ data.bookingLinks[0].title }}
                </custom-button>
            </div>
            <custom-image
                v-if="data.imageCourse"
                :image="data.imageCourse[0]"
                width="100%"
                container-height="auto"
                max-height="66vh"
                @click="visibleRef = !visibleRef"
                v-ripple
            />
            <vue-easy-lightbox
                v-if="data.imageCourse"
                :visible="visibleRef"
                :imgs="imgsRef"
                :index="indexRef"
                @hide="onHide"
                rotate-disabled
                zoom-disabled
                pinch-disabled
                move-disabled
            />
        </v-col>

        <v-col cols="12" md="6" lg="5" xl="4" class="information-container">
            <div class="spacing-bottom-xs">
                <headline-container
                    v-if="data.scorecard || data.scoreCardLinks"
                    mb="xs"
                >
                    Scorecard
                </headline-container>
                <custom-files v-if="data.scorecard" :files="data.scorecard" />
                <a
                    v-else-if="data?.scoreCardLinks && data?.scoreCardLinks[0]"
                    :href="data.scoreCardLinks[0].link"
                    target="_blank"
                    class="button-link hover-background-color"
                    v-ripple
                >
                    <img
                        class="custom-icon"
                        :src="api + '/frontend-icons/box-arrow-up-left.svg'"
                        alt="weiter"
                        width="30"
                        height="30"
                    />
                    <span class="link">
                        {{ data.scoreCardLinks[0].title }}
                    </span>
                </a>
            </div>
            <div
                class="spacing-bottom-xs"
                v-for="(area, a) in [
                    { name: 'Spielvorgaben Tabellen', info: data.requirements },
                    { name: 'Ausschreibungen RPR', info: data.announcements },
                    { name: 'Platzregeln', info: data.rules },
                ]"
                :key="a"
            >
                <headline-container v-if="area.name" mb="xs">
                    {{ area.name }}
                </headline-container>
                <custom-files v-if="area.info" :files="area.info" />
            </div>
        </v-col>
    </v-row>
</template>

<script setup>
import VueEasyLightbox, { useEasyLightbox } from "vue-easy-lightbox";
import { defineProps } from "vue";
import CustomImage from "@/components/reuseables/customImage.vue";
import CustomButton from "@/components/reuseables/customButton.vue";
import CustomFiles from "@/components/reuseables/customFiles.vue";
import { getImagesForLightBox } from "@/mixins/global/globalMethods";
import HeadlineContainer from "@/components/reuseables/headlineContainer.vue";
const api = process.env.VUE_APP_API_URL;

const props = defineProps({
        data: {
            type: Object,
            required: true,
        },
    }),
    {
        // methods
        onHide,
        // refs
        visibleRef,
        indexRef,
        imgsRef,
    } = useEasyLightbox({
        imgs: getImagesForLightBox(props?.data?.imageCourse),
        // initial index
        initIndex: 0,
    });
</script>

<style lang="scss" scoped>
.button-container {
    text-align: center;
}

.information-container {
    & > div {
        display: flex;
        flex-direction: column;
        .link-container {
            display: flex;
            align-items: center;
            padding: 1rem 0.5rem;
            text-decoration: none;
            background-color: white;
            color: var(--medium-color);
            .v-img {
                height: 2.5rem;
                width: 2.5rem;
                flex-grow: 0;
                margin-right: 1rem;
            }
            p {
                width: max-content;
                color: black;
                text-decoration: none;
            }
        }
    }
}

.button-link {
    color: var(--medium-color);
    height: auto;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 1rem 0.5rem;
    img {
        height: 2.5rem;
        width: 2.5rem;
        flex-grow: 0;
        margin-right: 1rem;
        margin-bottom: 0;
    }
    span {
        width: max-content;
        color: black;
        text-decoration: none;
        font-size: var(--text-size);
    }
}
</style>
