export const weekdays = [
    "Sonntag",
    "Montag",
    "Dienstag",
    "Mittwoch",
    "Donnerstag",
    "Freitag",
    "Samstag",
];

export const weekdaysSmall = ["So.", "Mo.", "Di.", "Mi.", "Do.", "Fr.", "Sa."];

export function getWeekday(index) {
    if (window.innerWidth > 720) return weekdays[index];
    else if (window.innerWidth <= 720) return weekdaysSmall[index];
}
