<template>
    <!--  <v-row>-->
    <!--    <v-col-->
    <!--      md="10"-->
    <!--      offset-md="1"-->
    <!--      offset-xl="2"-->
    <!--      xl="8"-->
    <!--    >-->
    <!--      <template v-if="items">-->
    <!--        <template v-for="(item, index) in items">-->
    <!--          <component-->
    <!--            :is="data.Template"-->
    <!--            :key="index"-->
    <!--            :class="localStorage ? 'customizer-outline' : ''"-->
    <!--            :data="item"-->
    <!--            :index="index"-->
    <!--          />-->
    <!--        </template>-->
    <!--      </template>-->

    <!--      <template v-if="data.Items">-->
    <!--        <template v-for="(item, index) in data.Items">-->
    <!--          <component-->
    <!--            :is="data.Template"-->
    <!--            :key="index"-->
    <!--            :data="item"-->
    <!--            :index="index"-->
    <!--          />-->
    <!--        </template>-->
    <!--      </template>-->
    <!--    </v-col>-->
    <!--  </v-row>-->
    <component
        v-if="Object.keys(res).length > 0 || data || data.content || items"
        :is="components[data.template]"
        :data="data"
        :content="data.content"
        :items="items"
        :listing="res"
    />
</template>

<script setup>
/* eslint-disable */
import { ref } from "vue";
import { defineProps, onMounted } from "vue";
import { apolloProvider } from "@/plugins/apollo";
import newsListing from "@/graphql/querie/listing/newsListing.graphql";
import holeOverview from "@/components/listings/holeOverview";
import newsOverview from "@/components/listings/newsOverview";
import imageStripe from "@/components/listings/imageStripe.vue";
import imageBoxBig from "@/components/listings/imageBoxBig.vue";
import teamOverview from "@/components/listings/teamOverview.vue";
import newsAll from "@/components/listings/newsAll.vue";
import lessonBox from "@/components/listings/lessonBox.vue";
import lessonsList from "@/components/listings/lessonsList.vue";
import partnerClubList from "@/components/listings/partnerClubList.vue";

const components = {
        newsOverview,
        imageStripe,
        imageBoxBig,
        holeOverview,
        teamOverview,
        newsAll,
        lessonBox,
        lessonsList,
        partnerClubList,
    },
    props = defineProps({
        data: Object,
        items: Object,
    }),
    api = process.env.VUE_APP_API_URL,
    res = ref({}),
    listingObject = { newsListing };

function loadListing() {
    res.value = {};
    if (props.data.listing) {
        apolloProvider.defaultClient
            .query({
                fetchPolicy: "no-cache",
                query: listingObject[props.data.listing + "Listing"],
                variables: {
                    lang: "de",
                },
            })
            .then((response) => {
                res.value =
                    response.data[
                        "get" +
                            props.data.listing.charAt(0).toUpperCase() +
                            props.data.listing.slice(1) +
                            "Listing"
                    ].edges;
            });
    }
}
onMounted(loadListing);
</script>
