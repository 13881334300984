// import {i18n} from "@/plugins/i18n";

export const checkFormFieldRules = (data) => {
    const rulesSet = [];
    if (data.rules) {
        data.rules.forEach((item) => {
            rulesSet.push(rules[item.rule](item.parameter, item.errorMessage));
        });
    }
    if (data.mandatory) {
        rulesSet.push(rules["notEmpty"]("Dieses Feld ist relevant"));
    }
    return rulesSet;
};

const rules = {
    // Required | Not empty
    notEmpty: function (message) {
        return (v) => !!v || (message ? message : "required");
    },
    // Min chars
    minChars: function (para, message) {
        return (v) => {
            if (v)
                return (
                    v.length >= parseInt(para) ||
                    (message ? message : "predefined Message")
                );
            return true;
        };
    },
    // Max chars
    maxChars: function (para, message) {
        return (v) => {
            if (v)
                return (
                    v.length <= parseInt(para) ||
                    (message ? message : "predefined Message")
                );
            return true;
        };
    },
    // MinValue
    minValue: function (para, message) {
        return (v) => {
            if (v)
                return (
                    parseInt(v) >= parseInt(para) ||
                    (message ? message : "predefined Message")
                );
            return true;
        };
    },
    // MaxValue
    maxValue: function (para, message) {
        return (v) => {
            if (v)
                return (
                    parseInt(v) <= parseInt(para) ||
                    (message ? message : "predefined Message")
                );
            return true;
        };
    },
    // Email
    email: function (para, message) {
        return (v) => {
            if (v)
                return (
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                        v
                    ) || (message ? message : "predefined Message")
                );
            return true;
        };
    },
    // Zipcode
    zipcode: function (para, message) {
        return (v) => {
            if (v)
                return (
                    /^[0-9]{5}$/.test(v) ||
                    (message ? message : "predefined Message")
                );
            return true;
        };
    },
    // Phone
    telephone: function (para, message) {
        return (v) => {
            if (v)
                return (
                    /^[\d /.()+-]+$/.test(v) ||
                    (message ? message : "predefined Message")
                );
            return true;
        };
    },
};
