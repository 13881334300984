<template>
    <field-headline
        :label="label"
        :additionalLabel="data.additionalLabel"
        :annotation="annotation"
    />

    <v-textarea
        :type="type"
        v-model="input.val"
        @input="emit('updated', props.name, input)"
        :rules="data ? checkFormFieldRules(data) : []"
        variant="outlined"
        rounded="0"
        :flat="true"
        :clearable="true"
    />
</template>

<script setup>
import { ref, defineProps, defineEmits } from "vue";
import FieldHeadline from "@/components/form/fields/fieldHeadline.vue";
import { checkFormFieldRules } from "@/plugins/form/validation";

const input = ref({ key: "", val: "" }),
    emit = defineEmits(["updated"]),
    props = defineProps({
        label: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            required: false,
            default: "text",
        },
        value: {
            type: Object,
            required: false,
            default() {
                return {
                    key: "",
                    val: false,
                };
            },
        },
        options: {
            type: Array,
            required: false,
        },
        data: {
            type: Object,
            required: false,
        },
        name: {
            type: String,
            required: false,
        },
        annotation: {
            type: Array,
            required: false,
        },
    });
</script>
