<template>
    <field-headline
        :label="label"
        :additionalLabel="data.additionalLabel"
        :annotation="annotation"
    />

    <v-text-field
        :type="type"
        v-model="input.val"
        @input="emit('updated', props.name, input)"
        :rules="data ? checkFormFieldRules(data) : []"
        variant="outlined"
        :clearable="true"
        :error="test"
        :prepend-icon="data.prependIcon"
        :prepend-inner-icon="data.prependInnerIcon"
        :append-inner-icon="data.appendInnerIcon"
        :append-icon="data.appendIcon"
    />
</template>

<script setup>
import { onMounted, ref, defineProps, defineEmits } from "vue";
import { checkFormFieldRules } from "@/plugins/form/validation";

import FieldHeadline from "@/components/form/fields/fieldHeadline.vue";

const emit = defineEmits(["updated"]),
    input = ref({ key: "", val: "" }),
    test = ref(false),
    props = defineProps({
        label: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            required: false,
            default: "text",
        },
        value: {
            type: Object,
            required: false,
            default() {
                return {
                    key: "",
                    val: false,
                };
            },
        },
        options: {
            type: Array,
            required: false,
        },
        data: {
            type: Object,
            required: false,
        },
        name: {
            type: String,
            required: false,
        },
        annotation: {
            type: Array,
            required: false,
        },
    });

onMounted(() => {
    if (props.value.key || props.value.val) {
        input.value = props.value;
    }
});
</script>
