<template>
  <v-dialog v-model="showDialog" width="660" class="modal">
    <v-card>
      <v-card-text>
        <h3 class="secondary-font">{{ data.headline }}</h3>
        <h4 class="primary-font-bold">{{ data.subline }}</h4>
        <div class="text" v-html="data.text"></div>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="hideModal">{{ data.closeButton }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>

import { defineProps, ref, onMounted } from 'vue'; 

const showDialog = ref(false);
const props = defineProps({
  data: Object,
});

onMounted(() => {
  const hiddenModals = JSON.parse(localStorage.getItem('hiddenModals')) || {};
  if (!hiddenModals[props.data.id] || hiddenModals[props.data.id] !== props.data.modificationDate) {
    showDialog.value = true;
  }
});

function hideModal() {
  const hiddenModals = JSON.parse(localStorage.getItem('hiddenModals')) || {};
  hiddenModals[props.data.id] = props.data.modificationDate;
  localStorage.setItem('hiddenModals', JSON.stringify(hiddenModals));
  showDialog.value = false;
}

</script>

<style lang="scss" scoped>
.modal {
  .v-card {
    border-radius: 0;
    box-shadow: 0px 4px 0px 0px #75943F inset;
  }
  h3, h4 {
    font-size: 30px;
  }
  .text {
    padding: 1rem 0;
  }
  .v-card-actions {
    display: flex;
    box-shadow: 0px 2px 0px 0px #000 inset, 0px -2px 0px 0px #000 inset;
    > button {
      flex-grow: 1;
    }
  }
}
</style>