<template>
    <v-col
        v-if="imageForText"
        order="2"
        order-lg="3"
        cols="12"
        offset-sm="1"
        sm="10"
        offset-md="2"
        md="8"
        offset-lg="0"
        lg="5"
        offset-xl="1"
        xl="4"
    >
        <div>
            <custom-image
                v-if="imageForText?.fullpath"
                @click="show"
                :image="imageForText"
                v-ripple
            />
            <vue-easy-lightbox
                :visible="visibleRef"
                :imgs="imgsRef"
                :index="indexRef"
                @hide="onHide"
                rotate-disabled
                zoom-disabled
                pinch-disabled
                move-disabled
            />
        </div>
    </v-col>
</template>

<script setup>
import VueEasyLightbox, { useEasyLightbox } from "vue-easy-lightbox";
import { defineProps } from "vue";
import CustomImage from "@/components/reuseables/customImage.vue";
import { getImagesForLightBox } from "@/mixins/global/globalMethods";

const props = defineProps({
        imageForText: Object,
    }),
    {
        // methods
        show,
        onHide,
        // refs
        visibleRef,
        indexRef,
        imgsRef,
    } = useEasyLightbox({
        imgs: getImagesForLightBox(props.data?.imageForText),
        // initial index
        initIndex: 0,
    });
</script>
