<template>
    <v-checkbox
        v-model="input.val"
        @input="emit('updated', props.name, input)"
        :rules="checkFormFieldRules(data)"
        variant="outlined"
        clearable
        false-icon="false"
        true-icon="false"
    >
        <template #label>
            {{ label }}
            <span class="font-weight-regular">
                {{ data.additionalLabel }}
            </span>
        </template>

        <template v-slot:input="{ model }">
            <svg
                width="160"
                height="160"
                viewBox="0 0 160 160"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style="transform: scale(0.5)"
                class="animated-line"
                :class="model.value ? 'forwards' : 'backwards'"
                @click="input.val = !input.val"
            >
                <g clip-path="url(#clip0_1_8)">
                    <rect width="160" height="160" fill="white" />
                    <rect
                        x="5"
                        y="5"
                        width="150"
                        height="150"
                        stroke="#003865"
                        stroke-width="10"
                    />
                    <line
                        x1="10.0711"
                        y1="9.92893"
                        x2="150.071"
                        y2="149.929"
                        stroke="#003865"
                        stroke-width="20"
                    />
                    <line
                        x1="9.92893"
                        y1="149.929"
                        x2="149.929"
                        y2="9.92894"
                        stroke="#003865"
                        stroke-width="20"
                    />
                </g>
            </svg>
        </template>
    </v-checkbox>
</template>

<script setup>
import { onMounted, ref, defineProps, defineEmits } from "vue";
import { checkFormFieldRules } from "@/plugins/form/validation";

const input = ref({ key: "", val: false }),
    emit = defineEmits(["updated"]),
    props = defineProps({
        label: {
            type: String,
            required: true,
        },
        value: {
            type: Object,
            required: false,
            default() {
                return {
                    key: "",
                    val: false,
                };
            },
        },
        options: {
            type: Array,
            required: false,
        },
        data: {
            type: Object,
            required: false,
        },
        name: {
            type: String,
            required: false,
        },
    });

onMounted(() => {
    if (props.value.key || props.value.val) {
        input.value = props.value;
    }
});
</script>

<style lang="scss" scoped>
.v-input {
    margin-bottom: -1rem;
    cursor: pointer;
}

:deep(label) {
    color: var(--color-primary);
    font-weight: bold;
    opacity: 1;
    font-size: 0.938rem;
    flex-direction: column;
    align-items: start;
}
:deep(.v-input__details) {
    display: none;
}
.v-input--error {
    :deep(.v-input__details) {
        display: block;
    }
}
:deep(i) {
    display: none;
}
.animated-line {
    &.forwards line {
        animation-name: drawLine;
    }
    &.backwards line {
        animation-name: removeLine;
    }
    line {
        stroke-dasharray: 200;
        animation-duration: 0.33s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
    }
}

@keyframes removeLine {
    0% {
        stroke-dashoffset: 0;
    }
    100% {
        stroke-dashoffset: 200;
    }
}
@keyframes drawLine {
    from {
        stroke-dashoffset: 200;
    }
    100% {
        stroke-dashoffset: 0;
    }
}
</style>
