<template>
    <template v-for="(file, f) in files" :key="f">
        <a
            v-if="file?.text && file?.file?.fullpath"
            class="link-container hover-background-color"
            :href="api + file.file.fullpath"
            v-ripple
            target="_blank"
        >
            <custom-image
                v-if="
                    file.file.fullpath.includes('webp') ||
                    file.file.fullpath.includes('png') ||
                    file.file.fullpath.includes('jpg') ||
                    file.file.fullpath.includes('gif') ||
                    file.file.fullpath.includes('jpeg') ||
                    file.file.fullpath.includes('svg') ||
                    file.file.fullpath.includes('avif')
                "
                :image="{ fullpath: '/frontend-icons/image.svg' }"
                container-width="auto"
                height="100%"
                container-height="auto"
                :hover="false"
            />
            <custom-image
                v-if="file.file.fullpath.includes('pdf')"
                :image="{ fullpath: '/frontend-icons/pdf.png' }"
                container-width="auto"
                height="100%"
                :hover="false"
                container-height="auto"
            />
            <p class="file link" v-if="file.text">
                {{ file.text }}
            </p>
        </a>
    </template>
</template>

<script setup>
import { defineProps } from "vue";
import CustomImage from "@/components/reuseables/customImage.vue";

defineProps({
    files: {
        type: [Array, null],
        required: true,
    },
});

const api = process.env.VUE_APP_API_URL;
</script>

<style lang="scss" scoped>
.link-container {
    display: flex;
    align-items: center;
    padding: 1rem 0.5rem;
    text-decoration: none;
    background-color: white;
    color: var(--medium-color);
    :deep(.image-container) {
        margin-bottom: 0;
    }
    :deep(.v-img) {
        height: 2.5rem;
        width: 2.5rem;
        flex-grow: 0;
        margin-right: 1rem;
        margin-bottom: 0;
    }
    p {
        width: max-content;
        color: black;
        text-decoration: none;
    }
}
</style>
