<template>
    <v-container v-if="data.id && years" class="game-report-container">
        <template v-for="(year, y) in years" :key="y">
            <v-row v-if="year.some((obj) => obj.visible === true)">
                <v-col cols="12">
                    <headline-container mt="sm">
                        Spielberichte {{ y }}
                    </headline-container>
                </v-col>

                <template v-for="(saison, key) in year" :key="key">
                    <v-col
                        v-if="saison.visible && saison.matchdays"
                        cols="12"
                        lg="3"
                    >
                        <div class="year">
                            <span v-if="saison.league">
                                {{ saison.league }}
                            </span>
                        </div>
                    </v-col>
                    <v-col
                        v-if="saison.visible && saison.matchdays[0]"
                        cols="12"
                        lg="9"
                    >
                        <v-expansion-panels variant="accordion">
                            <template
                                v-for="(match, key) in reverse(
                                    saison.matchdays
                                )"
                                :key="key"
                            >
                                <v-expansion-panel
                                    elevation="0"
                                    :collapse-icon="
                                        api + '/frontend-icons/dash.svg'
                                    "
                                    :expand-icon="
                                        api + '/frontend-icons/plus-lg.svg'
                                    "
                                    :disabled="
                                        !match.report || !match.report === null
                                    "
                                >
                                    <v-expansion-panel-title v-ripple>
                                        <template v-slot:actions="{ expanded }">
                                            <v-img
                                                :src="
                                                    expanded
                                                        ? api +
                                                          '/frontend-icons/dash.svg'
                                                        : api +
                                                          '/frontend-icons/plus-lg.svg'
                                                "
                                                width="1.5rem"
                                            />
                                        </template>
                                        <template v-slot:default>
                                            <span class="d-flex">
                                                <span class="date">
                                                    {{
                                                        changeDateToLanguage(
                                                            match.date,
                                                            "de-DE"
                                                        )
                                                    }}
                                                </span>
                                                <span class="dash">
                                                    &mdash;
                                                </span>
                                                <span
                                                    class="match-day primary-font-bold"
                                                >
                                                    {{ match.enemyTeam }}
                                                </span>
                                            </span>
                                        </template>
                                    </v-expansion-panel-title>
                                    <v-expansion-panel-text>
                                        <div
                                            class="secondary-font"
                                            v-html="match.report"
                                        />
                                        <game-report-gallery v-if="match.gallery && match.gallery.length > 0" :gallery="match.gallery" />
                                    </v-expansion-panel-text>
                                </v-expansion-panel>
                            </template>
                        </v-expansion-panels>
                    </v-col>
                </template>
            </v-row>
        </template>
    </v-container>
</template>

<script setup>
import { defineProps, onMounted, ref } from "vue";
import { changeDateToLanguage } from "@/mixins/global/globalMethods";
import HeadlineContainer from "@/components/reuseables/headlineContainer.vue";
import GameReportGallery from "@/pages/pageTeam/gameReportGallery.vue";

const props = defineProps({
        data: {
            type: Object,
            required: true,
        },
    }),
    years = ref({}),
    api = process.env.VUE_APP_API_URL;

function getYears() {
    years.value = {};

    props.data.saisons.forEach((saison) => {
        let year = saison.year.split("-")[0];
        if (!years.value[year]) years.value[year] = [];
        years.value[year].push(saison);
    });
}

onMounted(() => {
    getYears();
});

function reverse(matchdays) {
    let array = [];
    matchdays.forEach((e) => array.unshift(e));
    return array;
}
</script>

<style lang="scss" scoped>
.game-report-container {
    padding-bottom: 8rem !important;
    :deep(.v-expansion-panels) {
            z-index: 999;

        .v-expansion-panel {
            &.v-expansion-panel--active {
                padding: 0 !important;
                .v-expansion-panel-title {
                    min-height: auto;
                    height: auto;
                }
                .v-expansion-panel-title--active {
                    background-color: var(--light-color);
                }
            }
            &.v-expansion-panel--disabled {
                :deep(img) {
                    opacity: 0.26;
                }
            }
            .v-expansion-panel-title {
                border-bottom: 2px solid black;
                padding: 1rem 0.5rem 1rem 0;
                border-radius: unset;
                &:hover {
                    background-color: var(--light-color);
                }
                :deep(.v-expansion-panel-title__overlay) {
                    display: none;
                }
            }
            &::after {
                display: none;
            }

        }
        .v-expansion-panel-text__wrapper {
            padding: 1rem 0
        }
    }

    span.date {
        display: inline-block;
        min-width: 6.5rem;
    }
    span.dash {
        display: inline-block;
        margin: 0 0.25rem;
    }
    span.match-day {
    }
}

.year {
    border-bottom: 2px solid black;
    font-weight: bold;
    font-size: 24px;
    min-height: 50px;
    display: flex;
    align-items: center;
    @media (max-width: 1279px) {
        padding-top: 2rem;
    }
}
</style>
