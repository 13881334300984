<template>
    <table ref="table">
        <thead>
            <tr>
                <th
                    v-for="(day, d) in width > 1280 ? days : mobileDays"
                    :key="d"
                >
                    {{ day }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(club, c) in data.content" :key="c">
                <td>
                    <span class="club">
                        {{ club.element.name }}
                    </span>
                    <span class="phone">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            fill="currentColor"
                            class="bi bi-telephone"
                            viewBox="0 0 16 16"
                        >
                            <path
                                d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                            /></svg
                        >&nbsp;{{ club.element.telephone }}
                    </span>
                </td>
                <td
                    v-for="(pricing, p) in [
                        club.element.mondayPricing,
                        club.element.tuesdayPricing,
                        club.element.wednesdayPricing,
                        club.element.thursdayPricing,
                        club.element.fridayPricing,
                        club.element.saturdayPricing,
                        club.element.sundayPricing,
                    ]"
                    :key="p"
                    :data-value="pricing || ''"
                    colspan="1"
                >
                    <template v-if="pricing">
                        {{ pricing.toFixed(2).replace(".", ",") }}
                        {{ width >= 1280 ? currency : mobileCurrency }}
                    </template>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script setup>
import { defineProps, onMounted, ref } from "vue";

const table = ref(null),
    days = [
        "",
        "Montag",
        "Dienstag",
        "Mittwoch",
        "Donnerstag",
        "Freitag",
        "Samstag",
        "Sonntag",
    ],
    mobileDays = ["", "Mo.", "Di.", "Mi.", "Do.", "Fr.", "Sa.", "So."],
    currency = "EUR",
    mobileCurrency = "€",
    width = window.innerWidth;

defineProps({
    data: Object,
});

onMounted(() => {
    let tableBody = table.value.getElementsByTagName("tbody")[0],
        tableRows = tableBody.children;

    for (let row of tableRows) {
        Array.from(row.children).forEach((data, key) => {
            if (
                key !== 0 &&
                key !== 1 &&
                data.dataset.value !== "" &&
                data.previousElementSibling != null &&
                data.previousElementSibling?.dataset?.value ===
                    data.dataset.value
            ) {
                data.previousElementSibling.setAttribute(
                    "colspan",
                    parseInt(
                        data.previousElementSibling.getAttribute("colspan")
                    ) + 1
                );
                data.remove();
            }
        });
    }
});
</script>

<style lang="scss" scoped>
table {
    border-spacing: unset;
    overflow-y: auto;
    width: 100%;
    display: inline-block;
    border-collapse: unset;
    font-size: 1rem;

    thead {
        tr {
            th {
                border: 1px solid black;
                border-left: none;
                height: 4rem;
                font-weight: normal;
                background-color: white;
                font-size: 1rem;
                padding: 0 1.25rem;
                word-break: keep-all;
                min-width: 2rem;
                width: 100%;
                &:first-child {
                    max-width: 50%;
                }

                &:last-child {
                    border-right: none;
                }
            }
        }
    }
    tbody {
        tr {
            td {
                text-align: center;
                border: 1px solid black;
                border-top: none;
                border-left: none;
                vertical-align: middle;
                font-weight: bold;
                page-break-inside: avoid;
                white-space: nowrap;
                font-size: clamp(0.75rem, 1.5vw, 1.125rem);
                padding: 0 1.25rem;

                &:first-child {
                    text-align: left;
                    flex-direction: column;
                    position: sticky;
                    left: 0;
                    background-color: white;
                    padding-right: 1rem;
                    padding-left: 0;
                }
                &:last-child {
                    border-right: none;
                }
                span {
                    display: block;
                }
                span.club {
                    padding-top: 0.5rem;
                    line-height: initial;
                    margin-bottom: 0.25rem;
                }
                span.phone {
                    color: #9c9c9c;
                    font-weight: normal;
                    line-height: initial;
                    padding-bottom: 0.5rem;
                    svg {
                        margin-right: 0.25rem;
                        transform: translateY(1px);
                    }
                }
            }
        }
    }
}
</style>
