<template>
    <v-row>
        <template v-for="(team, key) in content" :key="key">
            <v-col cols="12" :lg="key < 2 ? 6 : 4">
                <router-link
                    class="team-container"
                    :to="'/team/' + team.element.url"
                    v-ripple
                >
                    <h2>
                        <span class="secondary-font">
                            {{ team.element.name }}</span
                        >
                        <span class="primary-font-bold">
                            {{ team.element.subtitle }}</span
                        >
                    </h2>
                    <custom-image
                        v-if="team.element.imageTeam"
                        :image="team.element.imageTeam[0]"
                        aspect-ratio="3/2"
                        :cover="true"
                    />
                    <div class="button-container" style="text-align: right">
                        <custom-button
                            :to="'/team/' + team.element.url"
                            min-height="3rem"
                        >
                            Infos & Spielbericht
                            <img
                                class="custom-icon"
                                :src="api + '/frontend-icons/arrow-right.svg'"
                                alt="weiter"
                            />
                        </custom-button>
                    </div>
                </router-link>
            </v-col>
        </template>
    </v-row>
</template>

<script setup>
import { defineProps } from "vue";
import CustomImage from "@/components/reuseables/customImage.vue";
import CustomButton from "@/components/reuseables/customButton.vue";
const api = process.env.VUE_APP_API_URL;

defineProps({
    data: Object,
    content: Array,
});
</script>

<style lang="scss" scoped>
[class*="v-col"] {
    margin-bottom: 3rem;
    a.team-container {
        text-decoration: none;
        color: black;
        border-top: 2px solid black;
        border-bottom: 2px solid black;
        display: flex;
        flex-direction: column;

        h2 {
            margin: 0.25rem 0;
            span {
                display: block;
            }
        }
        a {
            text-transform: none;
        }
        .image-container {
            overflow: hidden;
        }
    }
}
</style>
