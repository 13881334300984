<template>
    <div v-if="data">
        <iframe
            v-if="data?.video?.type === 'youtube'"
            width="100%"
            :src="'https://www.youtube.com/embed/' + data?.video?.videoData?.youtubeID"
        />
        <video v-else-if="data?.video?.type === 'asset'"
               controls
               :title="data?.title"
               :aria-label="data?.description"
               :poster="data?.video?.poster?.fullpath ? apiUrl + data?.video?.poster?.fullpath : undefined">>
            <source :src="apiUrl + data?.video?.videoData?.fullpath" type="video/mp4"/>
            Your browser does not support the video tag.
        </video>
    </div>
    <h2 v-if="data.headline" class="mt-2 mb-4">
        {{ data.headline }}
    </h2>
    <p v-if="data.text">{{ data.text }}</p>
</template>

<script setup>
import {defineProps} from "vue";

defineProps({
    data: {
        type: Object,
        required: true
    },
});

const apiUrl = process.env.VUE_APP_API_URL;
</script>

<style scoped>
iframe {
    aspect-ratio: 16/9;
}

video {
    width: 100%;
    max-height: 100vh;
}
</style>
