<template>
    <v-app-bar elevation="0">
        <v-container>
            <router-link
                v-if="header.logo"
                to="/"
                title="Zur Startseite"
                class="logo"
            >
                <custom-image
                    :image="header.logo[0]"
                    background-color="white"
                    width="100%"
                    container-height="100%"
                    :hover="false"
                />
            </router-link>

            <navigation-header-desktop
                v-if="!$isMobile() && windowSize.x >= 1280"
                :header="header"
            />

            <navigation-header-mobile v-else :header="header" />
        </v-container>
    </v-app-bar>
</template>

<script setup>
import { computed, defineProps } from "vue";
import CustomImage from "@/components/reuseables/customImage.vue";
import NavigationHeaderMobile from "@/components/navigation/navigationHeader/navigationHeaderMobile.vue";
import NavigationHeaderDesktop from "@/components/navigation/navigationHeader/navigationHeaderDesktop.vue";

defineProps({
    header: {
        type: Object,
        required: true,
    },
});

const windowSize = computed(function () {
    return { x: window.innerWidth, y: window.innerHeight };
});
</script>

<style lang="scss" scoped>
.desktop-navigation {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: end;
    max-width: calc(100% - 5rem);
    float: right;
}

.primary-button,
.secondary-button {
    font-size: 1.125rem;
    text-transform: lowercase;
    * {
        font-size: 1.125rem;
    }
}

.v-container {
    position: relative;
}
header {
    overflow: initial;
    height: 5rem;
    :deep(.v-toolbar__content) {
        height: 100% !important;
    }
}
.v-container {
    overflow: initial;
}
.v-list {
    background-color: red;
    .v-list-item {
        padding: 0 !important;
        .v-list-item-title {
            padding: 0.25rem 1rem;
            font-size: 1.125rem;
            text-transform: lowercase;
        }
    }
}

.logo {
    display: inline-block;
    height: 7rem;
    width: 7rem;
    border: 1rem solid white;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 1rem;
    overflow: initial;
    max-height: none;
    max-width: none;
    @media (max-width: 959px) {
        height: 6rem;
        width: 6rem;
        top: 1rem;
    }
}

:deep(a),
:deep(button) {
    text-decoration: none;
    color: black;
    text-transform: lowercase !important;
    font-size: 16px !important;
}

.mobile-navigation-button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: end;
    max-width: calc(100% - 5rem);
    float: right;
}
</style>
