<template>
    <object_header
        v-if="data.imageHeader"
        :data="{
            headline: 'Aktuelle',
            subHeadline: 'Club-News',
            images: [
                data.imageHeader[0] && data.imageHeader[0].image
                    ? data.imageHeader[0]
                    : {
                          title: null,
                          copyright: null,
                          alt: null,
                          image: {
                              fullpath:
                                  '/schoenbuch/Website/00%20News/6726/image-thumb__6726__360/news-standardheader.jpg',
                              original:
                                  '/schoenbuch/Website/00 News/news-standardheader.jpg',
                          },
                      },
            ],
            sizeOfHero: 'small',
        }"
    />

    <transition-group name="fade-scale" appear>
        <v-container v-if="data.id">
            <page-news-back />
            <v-row>
                <v-col cols="12" sm="12" md="7" lg="7">
                    <page-news-text v-if="data.id" :data="data" />
                </v-col>

                <v-col cols="12" sm="12" md="5" lg="5">
                    <page-news-gallery v-if="data.id" :data="data" />
                    <page-news-links :links="data.links" />
                    <page-news-files :files="data.files" />
                </v-col>
            </v-row>

            <news-latest-listing v-if="data.id" />
        </v-container>
    </transition-group>

    <custom-loading v-if="loading" />
    <page-not-found v-if="!loading && !data.id" />
</template>

<script setup>
import { apolloProvider } from "@/plugins/apollo";
import { useHead } from "unhead";
import { useRoute } from "vue-router";
import { onMounted, ref, watch } from "vue";
import { GET_NEWS } from "@/graphql/querie/pages/news";
import Object_header from "@/components/object_header.vue";
import NewsLatestListing from "@/components/listings/newsLatestListing.vue";
import PageNewsBack from "@/pages/pageNews/pageNewsBack.vue";
import PageNewsText from "@/pages/pageNews/pageNewsText.vue";
import PageNewsGallery from "@/pages/pageNews/pageNewsGallery.vue";
import PageNotFound from "@/pages/pageNotFound.vue";
import CustomLoading from "@/components/reuseables/loading.vue";
import PageNewsLinks from "@/pages/pageNews/pageNewsLinks.vue";
import PageNewsFiles from "@/pages/pageNews/pageNewsFiles.vue";

const route = ref(useRoute()),
    data = ref({}),
    loading = ref(true);

function setPageMetadata() {
    if (data.value.seo) {
        useHead({
            title: "News | " + data.value.seo[0].title,
            link: [
                {
                    name: "canonical",
                    content: data.value.seo[0].canonical,
                },
            ],
            meta: [
                {
                    name: "description",
                    content: data.value.seo[0].description,
                },
                {
                    name: "keywords",
                    content: data.value.seo[0].keywords,
                },
                {
                    robots: "robots",
                    content: data.value.seo[0].robots || "index, follow",
                },
            ],
        });
    }
}

function loadNews() {
    data.value = {};
    loading.value = true;
    apolloProvider.defaultClient
        .query({
            fetchPolicy: "no-cache",
            query: GET_NEWS,
            variables: {
                filter:
                    '{"slug": {"$like" :"' + route.value.params.slug + '"}}',
                lang: "de",
            },
        })
        .then((response) => {
            loading.value = false;

            if (response.data.getNewsListing.edges[0]) {
                data.value = response.data.getNewsListing.edges[0].node;
            } else {
                data.value = {};
            }

            setPageMetadata();
        });
}

onMounted(loadNews);

watch(
    () => route.value.path,
    () => {
        loadNews();
    },
    { immediate: true, deep: true }
);
</script>
