<template>
    <object_header
        v-if="data?.imageHeader"
        :data="{
            headline: data.name,
            subHeadline: data.subtitle,
            images: [data.imageHeader[0]],
        }"
    />
    <transition-group name="fade-scale" appear>
        <div :key="'lightbox'">
            <vue-easy-lightbox
                v-if="data.imageTeam"
                :visible="visibleRef"
                :imgs="imgsRef"
                :index="indexRef"
                @hide="onHide"
                rotate-disabled
                zoom-disabled
                pinch-disabled
                move-disabled
            />
        </div>
        <v-container v-if="data.id">
            <back-button
                :buttons="[
                    {
                        link: '/mannschaften',
                        title: 'Zurück zur Mannschaftsübersicht',
                    },
                ]"
            />

            <v-row>
                <v-col cols="12" md="7" lg="8">
                    <headline-container mb="xs">
                        Mannschaftskader
                    </headline-container>

                    <custom-image
                        v-if="data.imageTeam"
                        :image="data.imageTeam[0]"
                        class="mb-4"
                        container-height="inherit"
                        @click="(visibleRef = !visibleRef), (indexRef = 0)"
                    />

                    <page-team-members :data="data" />

                    <div v-for="(info, key) in data.informations" :key="key">
                        <headline-container mt="md" mb="xs">
                            {{ info.headline }}
                        </headline-container>
                        <custom-image
                            :image="info.image"
                            class="mb-4"
                            @click="
                                (visibleRef = !visibleRef), (indexRef = 1 + key)
                            "
                        />
                        <h3>{{ info.subheadline }}</h3>
                        <p v-html="$sanitizeHtml(info.text)" />
                    </div>
                </v-col>

                <v-col cols="12" md="5" lg="4">
                    <template
                        v-for="(saison, key) in data.saisons"
                        :key="'saison-' + key"
                    >
                        <div
                            v-if="saison.visible && saison.matchdays"
                            class="saison"
                        >
                            <headline-container mb="xs">
                                <span v-if="saison.league">
                                    {{ saison.league }}
                                </span>
                            </headline-container>
                            <table class="saison-table spacing-bottom-xs">
                                <tr>
                                    <th>Datum</th>
                                    <th>Spielort</th>
                                </tr>

                                <tr
                                    v-for="(match, key) in saison.matchdays"
                                    :key="'match-day-' + key"
                                >
                                    <td>
                                        {{
                                            changeDateToLanguage(
                                                match.date,
                                                "de-DE"
                                            )
                                        }}
                                    </td>
                                    <td>{{ match.location }}</td>
                                </tr>
                            </table>
                        </div>
                    </template>
                </v-col>

                <page-team-league
                    v-if="data.iframeLink"
                    :link="data.iframeLink"
                />
            </v-row>
        </v-container>
    </transition-group>

    <game-report v-if="data.id" :data="data" />
    <custom-loading v-if="loading" />
    <page-not-found v-if="!loading && !data.id" />
</template>

<script setup>
import { apolloProvider } from "@/plugins/apollo";
import { GET_TEAM } from "@/graphql/querie/pages/team";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import CustomImage from "@/components/reuseables/customImage.vue";
import HeadlineContainer from "@/components/reuseables/headlineContainer.vue";
import VueEasyLightbox from "vue-easy-lightbox";
import { useHead } from "unhead";
import {
    changeDateToLanguage,
    getImagesForLightBox,
} from "@/mixins/global/globalMethods";
import PageTeamLeague from "@/pages/pageTeam/pageTeamLeague.vue";
import PageNotFound from "@/pages/pageNotFound.vue";
import PageTeamMembers from "@/pages/pageTeam/pageTeamMembers.vue";
import GameReport from "@/pages/pageTeam/gameReport.vue";
import BackButton from "@/pages/PageLesson/backButton.vue";
import CustomLoading from "@/components/reuseables/loading.vue";

const route = useRoute(),
    data = ref({}),
    loading = ref(true),
    onHide = ref(),
    visibleRef = ref(),
    indexRef = ref(),
    imgsRef = ref();

function setPageMetadata() {
    if (data.value.seo) {
        useHead({
            title: "Team | " + data.value.seo[0].title,
            link: [
                {
                    name: "canonical",
                    content: data.value.seo[0].canonical,
                },
            ],
            meta: [
                {
                    name: "description",
                    content: data.value.seo[0].description,
                },
                {
                    name: "keywords",
                    content: data.value.seo[0].keywords,
                },
                {
                    robots: "robots",
                    content: data.value.seo[0].robots || "index, follow",
                },
            ],
        });
    }
}

function loadHole() {
    data.value = {};
    loading.value = true;
    apolloProvider.defaultClient
        .query({
            fetchPolicy: "no-cache",
            query: GET_TEAM,
            variables: {
                filter: '{"url": {"$like" :"' + route.params.fullpath + '"}}',
                lang: "de",
            },
        })
        .then((response) => {
            loading.value = false;
            if (response?.data?.getTeamListing?.edges[0]?.node) {
                data.value = response.data.getTeamListing.edges[0].node;
                setPageMetadata();

                onHide.value = () => (visibleRef.value = false);
                visibleRef.value = false;
                indexRef.value = 0;
                imgsRef.value = getImagesForLightBox(data.value?.imageTeam);

                if (data.value?.informations?.length > 0) {
                    getImagesForLightBox(data.value?.informations).forEach(
                        (e) => {
                            imgsRef.value.push(e);
                        }
                    );
                }
            } else {
                data.value = {};
            }
        });
}

onMounted(() => {
    loadHole();
});
</script>

<style lang="scss" scoped>
.saison {
    .saison-table {
        width: 100%;
        tr {
            &:nth-child(2n) {
                background-color: var(--light-color);
            }
            th,
            td {
                text-align: left;
                padding: 0.5rem;
                font-size: 1rem;
                &:first-child {
                    padding-left: 0;
                }
                &:last-child {
                    padding-right: 0;
                }
            }
            th:first-child {
                width: 10rem;
            }
        }
    }
}
</style>
