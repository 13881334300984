// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("acta-bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("acta-bold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("acta-book.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("acta-book.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("campton-bold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("campton-bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("campton-book.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("campton-book.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:Acta;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\");font-weight:700;font-style:normal}@font-face{font-family:Acta;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff2\");font-weight:400;font-style:normal}@font-face{font-family:Campton;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"woff2\");font-weight:700;font-style:normal}@font-face{font-family:Campton;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format(\"woff2\");font-weight:400;font-style:normal}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
