<template>
    <navigation-dates @date-update="updateDataBasedOnDate($event)" />

    <v-row v-if="windowSize >= 960" :key="activeData.id">
        <v-col v-if="activeData && data.mainCourseActive" v-bind="activeCols">
            <main-course :data="activeData" :changes="detectChanges(activeData.changesMainCourse)" :activeDate="activeDate" />
        </v-col>

        <v-col v-if="data.publicCourseActive" v-bind="activeCols">
            <public-course :data="activeData" :changes="detectChanges(activeData.changesPublicCourse)" :activeDate="activeDate" />
        </v-col>

        <v-col v-if="data.drivingRangeActive" v-bind="activeCols">
            <driving-range :data="activeData" />
            {{ data.weatherApi }}
        </v-col>
    </v-row>

    <div v-else class="swiper object-course-information">
        <div class="swiper-wrapper">
            <div
                v-if="activeData && data.mainCourseActive"
                class="swiper-slide"
            >
                <main-course :data="activeData" :changes="detectChanges(activeData.changesMainCourse)" :activeDate="activeDate"  />
            </div>
            <div
                v-if="data.publicCourseActive"
                class="swiper-slide"
                v-bind="activeCols"
            >
                <public-course :data="activeData" :changes="detectChanges(activeData.changesPublicCourse)" :activeDate="activeDate" />
            </div>
            <div
                v-if="data.drivingRangeActive"
                class="swiper-slide"
                v-bind="activeCols"
            >
                <driving-range :data="activeData" />
                {{ data.weatherApi }}
            </div>
        </div>

        <div class="swiper-button-prev top">
            <img
                class="custom-icon"
                :src="api + '/frontend-icons/arrow-left.svg'"
                alt="weiter"
            />
        </div>
        <div class="swiper-button-next top">
            <img
                class="custom-icon"
                :src="api + '/frontend-icons/arrow-right.svg'"
                alt="weiter"
            />
        </div>
    </div>
</template>

<script setup>
import { computed, defineProps, ref, onMounted } from "vue";
import DrivingRange from "@/components/object_courseInformation/drivingRange.vue";
import MainCourse from "@/components/object_courseInformation/mainCourse.vue";
import PublicCourse from "@/components/object_courseInformation/publicCourse.vue";
import NavigationDates from "@/components/object_courseInformation/navigationDates.vue";
import Swiper from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

const swiper = ref(),
    api = process.env.VUE_APP_API_URL,
    props = defineProps({
        data: {
            type: Object,
            required: true,
        },
    }),
    windowSize = window.innerWidth,
    activeDate = ref(''),
    activeData = ref(props.data);

const activeCols = computed(() => {
    let amount = 0,
        cols = { cols: "12" };

    if (props.data.mainCourseActive) amount++;
    if (props.data.publicCourseActive) amount++;
    if (props.data.drivingRangeActive) amount++;
    if (amount === 2) {
        cols = {
            cols: "12",
            md: "6",
        };
    }
    if (amount === 3) {
        cols = {
            cols: "12",
            md: "6",
            lg: "4",
        };
    }

    return cols;
});

function createSwiper() {
    swiper.value = new Swiper(".swiper.object-course-information", {
        modules: [Navigation],
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        spaceBetween: 24,
        slidesPerView: 1,
        breakpoints: {
            800: {
                slidesPerView: 2,
            },
        },
    });
}

function updateDataBasedOnDate(date) {
    activeDate.value = date;
}

/**
 * @param {Array} changes
 */
function detectChanges(changes){
    const datesWithChanges = {}
    if(changes === null) return;
    changes.forEach((change) => {
        let start = new Date(change.starting);
        start.setDate(start.getDate() + 1);

        let end = new Date(change.ending);
        end.setDate(end.getDate() + 1);

        for(let day = start; day <= end; day.setDate(day.getDate() + 1)) {
            let dateKey = day.toISOString().split('T')[0];
            if(!datesWithChanges[dateKey]) {
                datesWithChanges[dateKey] = {}
            }
            datesWithChanges[dateKey][change.topic] = {
                "newValue": !!change.newValue,
                "annotation": change.annotation
            };
        }
    });
    return datesWithChanges;
}

onMounted(() => {
    updateDataBasedOnDate(new Date().toISOString().split("T")[0]);
    createSwiper();
});
</script>

<style scoped lang="scss">
:deep(h2) {
    @media (max-width: 1919px) {
        max-width: calc(100% - 6rem);
        text-align: center;
    }
}

:deep(.swiper-button-next),
:deep(.swiper-button-prev) {
    &:after {
        content: "";
    }
}

:deep(.main-information) {
    background-color: var(--light-color);
    padding: 22px 30px;
    margin-bottom: 2rem;
    .information-row {
        margin-bottom: 0.75rem;
    }
}

:deep(.information-row) {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
}
:deep(h3) {
    font-size: 18px;
}
</style>
