<template>
    <v-row>
        <v-col
            cols="12"
            lg="6"
            v-for="(place, j) in [
                {
                    title: data.clubTitle,
                    text: data.clubText,
                    place: data.sectionsClub,
                },
                {
                    title: data.publicTitle,
                    text: data.publicText,
                    place: data.sectionsPublic,
                },
            ]"
            :key="j"
        >
            <headline-container
                v-if="place.title"
                :class="j === 1 ? 'mt-16 mt-lg-0' : ''"
                mb="xs"
            >
                {{ place.title }}
            </headline-container>
            <p v-if="place.text" class="text-area secondary-font">
                {{ place.text }}
            </p>
            <template v-for="(section, key) in place.place" :key="key">
                <table>
                    <tr>
                        <th colspan="2" class="title">
                            {{ section.title }}
                        </th>
                    </tr>
                    <tr v-if="section.subtitle">
                        <th colspan="2" class="subtitle">
                            {{ section.subtitle }}
                        </th>
                    </tr>
                    <tr
                        v-for="(price, p) in section.pricing"
                        :key="p"
                        :class="p === 0 ? 'first-element' : ''"
                    >
                        <td>
                            <template v-if="price.element.textIcons">
                                <v-img
                                    v-for="(image, key) in price.element
                                        .textIcons"
                                    :key="key"
                                    :src="api + image.image.fullpath"
                                    width="2rem"
                                    class="d-inline-block mr-4"
                                />
                            </template>
                            <span
                                v-html="price.element.text"
                                class="d-inline-block"
                            />
                        </td>
                        <td>
                            <template v-if="price.element.soldOut">
                                ausverkauft
                            </template>
                            <template v-else>
                                {{
                                    Number.parseFloat(
                                        price.element.price
                                    ).toFixed(2)
                                }}&nbsp;€
                            </template>
                        </td>
                    </tr>
                </table>
                <template v-for="(price, p) in section.pricing" :key="p">
                    <div
                        v-if="price.element.info"
                        v-html="price.element.info"
                        class="info-text"
                    />
                </template>
            </template>
        </v-col>
    </v-row>
</template>

<script setup>
import { defineProps } from "vue";
import HeadlineContainer from "@/components/reuseables/headlineContainer.vue";

defineProps({
    data: {
        type: Object,
        required: true,
    },
});

const api = process.env.VUE_APP_API_URL;
</script>

<style lang="scss" scoped>
.headline-container {
    margin-bottom: 0;
}
.text-area {
    padding: 2rem 2rem 4rem 2rem;
    background-color: rgba(117, 148, 63, 0.1);
    min-height: 12rem;
    margin-bottom: 3rem;
}
table {
    width: 100%;
    margin-top: 4rem;
    border-spacing: 0;
    tr {
        th,
        td {
            text-align: left;
            &.title {
                font-weight: bold;
            }
            &.subtitle {
                font-weight: normal;
            }
        }
        th {
        }
        td {
            padding: 0.75rem 0;
            border-bottom: 1px solid rgba(117, 148, 63, 0.1);
        }
        &.first-element {
            td {
                border-top: 2px solid black;
            }
        }
        td {
            &:last-child {
                word-break: keep-all;
            }
        }
    }
}
.info-text {
    margin-top: 1rem;
    :deep(ul) {
        padding-left: 1.5rem;
    }
}
</style>
