<template>
    <v-parallax ref="customImage" v-if="image" :src="fullpath" />
</template>

<script setup>
import { defineProps, onMounted, ref } from "vue";

const api = process.env.VUE_APP_API_URL,
    customImage = ref(),
    fullpath = ref(""),
    props = defineProps({
        image: {
            type: Object,
            required: true,
        },
    });
//
// const computedImage = computed(() => {
//     const objectKey = Object.keys(props.image).slice(1);
//     const divided = Math.floor(vuetify.display.width.value / 120);
//     if (props.image[objectKey[divided]])
//         return api + props.image[objectKey[divided]];
//     else return api + props.image[objectKey[objectKey.length - 1]];
// });

function replaceLink(string, to) {
    if (to === "original") return props.image.original;
    else return string.replace("60px", to);
}

function defineThumbnail() {
    if (customImage?.value?.$el?.offsetWidth) {
        //GET WITH OF THE IMAGE
        let w = customImage.value.$el.offsetWidth;

        // GET SOURCE FROM POSSIBLE DATA
        let source = "";
        // if (this.src) source = this.src;
        // else if (this.data && this.data.fullpath) source = this.data.fullpath;
        // else
        if (props.image) source = props.image.fullpath;

        // DEFINE THUMBNAIL
        let definedThumbnail = "";
        if (w >= 1320) definedThumbnail = "1920";
        else if (w >= 800 && w < 1320) definedThumbnail = "1280";
        else if (w >= 400 && w < 800) definedThumbnail = "720";
        else if (w < 400) definedThumbnail = "360";

        // RETURN DATA
        fullpath.value = api + replaceLink(source, definedThumbnail);
    }

    return "false";
}

onMounted(() => {
    defineThumbnail();
});
</script>

<style scoped></style>
