<template>
    <headline-container v-if="data.publicCourseName" mb="xs">
        {{ data.publicCourseName }}
    </headline-container>

    <div class="main-information">
        <div class="information-row">
            <span>Geöffnet:</span>
            <true-false :boolean="data.publicCourseOpen"/>
        </div>
        <div
            v-if="data.publicCourseOpening || data.publicCourseClosing"
            class="information-row"
        >
            Startzeiten:<br>
            {{ data.publicCourseOpening }} bis {{ data.publicCourseClosing }} Uhr
        </div>
        <div
            v-if="data.publicCourseGeneralText"
            v-html="data.publicCourseGeneralText"
        />
    </div>

    <div
        v-for="(information, k) in publicCourseInformation"
        :key="k"
        class="basic-information"
    >
        <h3>
            {{ information.title }}
        </h3>

        <template
            v-for="(row, key) in information.info"
            :key="key"
        >
            <div
                v-if="row.text"
                class="information-row"
            >
                <template v-if="row.text === 'Aerifiziert'">
                    <span> {{ row.text }}
                        <template v-if="aerateDate">
                            (am {{ aerateDate }})
                        </template>
                    </span>
                    <true-false v-if="row.boolean != null" :boolean="row.boolean"/>
                </template>
                <template v-else>
                    <span v-html="row.text"/>
                    <true-false v-if="row.boolean != null" :boolean="row.boolean"/>
                </template>
            </div>
        </template>
    </div>
</template>

<script setup>
import {defineProps, computed} from "vue";
import HeadlineContainer from "@/components/reuseables/headlineContainer.vue";
import TrueFalse from "@/components/object_courseInformation/trueFalse.vue";

const props = defineProps({
    data: {
        type: Object,
        required: true,
    },
    activeDate: {
        type: String,
        required: true,
    },
    changes: {
        type: Object,
        required: false
    }
});

const aerateDate = computed(() => {
    console.log("test")
    console.log(props)
    if (!props.changes) return "";
    const dates = Object.keys(props.changes);
    const filteredDates = dates.filter(date => Object.prototype.hasOwnProperty.call(props.changes[date], 'aerate'));
    const lastDate = filteredDates[filteredDates.length - 1];
    if (lastDate) {
        const dateObject = new Date(lastDate);
        return dateObject.toLocaleDateString('de-DE');
    }
    return null;
});

const publicCourseInformation = computed(() => {
    let changesForActiveDate = {}
    if (props.changes && props.changes[props.activeDate]) {
        changesForActiveDate = props.changes[props.activeDate];
    }
    return [
        {
            title: "Platzinformation",
            info: [
                {
                    text: "E-Trolley erlaubt",
                    boolean: changesForActiveDate?.trolley?.newValue ?? props.data.publicCourseTrolley
                },
                {
                    text: "E-Carts erlaubt",
                    boolean: changesForActiveDate?.eCarts?.newValue ?? props.data.publicCourseECarts
                },
                {
                    text: "Wintergrüns",
                    boolean: changesForActiveDate?.winterGreen?.newValue ?? props.data.publicWinterGreen
                },
                {
                    text: props.data.publicCourseInformationWYSIWYG,
                    boolean: null,
                },
            ],
        },
        {
            title: "Pflegearbeiten",
            info: [
                {
                    text: "Vertikutieren/Sanden des Grüns",
                    boolean: changesForActiveDate?.verticutate?.newValue ?? props.data.publicCourseVerticutate,
                },
                {
                    text: "Aerifiziert",
                    boolean: changesForActiveDate?.aerate?.newValue ?? props.data.publicCourseAerate,
                },
                {
                    text: props.data.publicCourseMaintenanceWYSIWYG,
                    boolean: null,
                },
            ],
        },
    ];
});
</script>

<style scoped lang="scss">
.basic-information {
    padding-bottom: 2rem;

    h3 {
        border-bottom: 2px solid black;
        padding: 0.75rem 0;
        text-transform: none;
        font-weight: normal;
    }

    .information-row {
        padding: 0.5rem 0;
        border-bottom: 1px solid var(--medium-color);

        a {
            color: inherit;
        }
    }

    :deep(p) {
        margin-top: 1rem;
    }
}
</style>
