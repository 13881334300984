import gql from "graphql-tag";

import generalImages from "../fragments/general/generalImages.graphql";
import generalLinks from "../fragments/general/generalLinks.graphql";
import generalSeo from "../fragments/general/generalSeo.graphql";

import teamMember from "../fragments/teamMember.graphql";
import trainer from "../fragments/trainer.graphql";

export const GET_TEAM = gql`
    ${generalImages}
    ${generalLinks}
    ${generalSeo}

    ${teamMember}
    ${trainer}

    query ($filter: String) {
        getTeamListing(filter: $filter, defaultLanguage: "de") {
            edges {
                node {
                    id
                    name
                    subtitle
                    url
                    imageTeam {
                        ...generalImages
                    }
                    imageHeader {
                        ...generalImages
                    }
                    trainer {
                        ...trainer
                        ...teamMember
                    }
                    captain {
                        ...teamMember
                    }
                    vizeCaptain {
                        ...teamMember
                    }
                    member {
                        element {
                            ...teamMember
                        }
                    }
                    matches {
                        ... on fieldcollection_teamMatches {
                            gameday
                            enemyTeam
                            home
                            date
                            report
                            result
                        }
                    }
                    saisons {
                        ... on fieldcollection_teamSaisons {
                            year
                            league
                            visible
                            matchdays {
                                ... on object_gameday {
                                    gameday
                                    enemyTeam
                                    location
                                    home
                                    date: date_Formatter
                                    report
                                    result
                                    gallery {
                                        image {
                                            id
                                            fullpath (thumbnail: "360")
                                            original: fullpath
                                        }
                                    }
                                }
                            }
                        }
                    }
                    informations {
                        ... on fieldcollection_teamInformation {
                            headline
                            subheadline
                            text
                            image {
                                fullpath(thumbnail: "360")
                                original: fullpath
                            }
                        }
                    }
                    iframeLink {
                        ...generalLinks
                    }
                    seo {
                        ...generalSeo
                    }
                }
            }
        }
    }
`;
