<template>
    <router-link
        v-if="link"
        :to="link"
        v-ripple
        class="teaser-box"
        :data-id="data.id"
    >
        <custom-image
            v-if="data.imagesTeaser"
            :image="data.imagesTeaser[0]"
            width="100%"
            max-height="50vh"
            cover
            :aspect-ratio="aspectRatio"
        />
        <h2>
            <span class="secondary-font">{{ data.headline }} </span> <br />
            <span class="primary-font-bold"> {{ data.subHeadline }}</span>
        </h2>
    </router-link>
</template>

<script setup>
import { defineProps, computed, ref } from "vue";
import CustomImage from "@/components/reuseables/customImage.vue";
import { vuetify } from "@/plugins/vuetify";

const props = defineProps({
    data: Object,
    listingData: Object,
});

const aspectRatio = computed(() => {
    const display = ref(vuetify.display);

    let aspectRatio = {
        base: props.listingData.baseAR || "16/9",
        sm: props.listingData.smallAR || "16/9",
        md: props.listingData.mediumAR || "16/9",
        lg: props.listingData.largeAR || "16/9",
        xl: props.listingData.extraLargeAR || "16/9",
        xxl: props.listingData.xxlAR || "16/9",
    };

    for (const size of ["xxl", "xl", "lg", "md", "sm"]) {
        if (display.value[size]) {
            return aspectRatio[size];
        }
    }

    return aspectRatio.base;
});

const link = computed(function () {
    let data = props.data.links;
    if (data && data[0]) {
        if (data[0]?.dataObject?.url) return data[0].dataObject.url;
        else if (data[0]) return data[0].link;
    }
    return "";
});
</script>

<style lang="scss" scoped>
a {
    display: block;
    border-bottom: 2px solid black;
    color: black;
    padding-bottom: 1rem;
    transition: border 0.33s ease;
    text-decoration: none;
    @media (max-width: 599px) {
        margin-bottom: 3rem;
    }
    &:hover {
        border-bottom: 2px solid rgb(117, 148, 63);
    }
    h2 {
        margin-top: 1rem;
        span {
            font-size: 30px;
            line-height: 29px;
        }
    }
}
</style>
