<template>
    <v-app>
        <navigation-header :header="header" />
        <v-main>
            <navigation-sidebar :sidebar="sidebar" />
            <router-view />
        </v-main>
        <navigation-footer :footer="footer" />
    </v-app>
</template>

<script>
import header from "@/graphql/querie/navigation.graphql";
import footer from "@/graphql/querie/footer.graphql";
import sidenavigation from "@/graphql/querie/sidenavigation.graphql";
import NavigationHeader from "@/components/navigation/navigationHeader.vue";
import NavigationFooter from "@/components/navigation/navigationFooter.vue";
import NavigationSidebar from "@/components/navigation/navigationSidebar.vue";

export default {
    name: "App",
    components: {
        NavigationSidebar,
        NavigationFooter,
        NavigationHeader,
    },
    data() {
        return {
            header: {},
            footer: {},
            sidebar: {},
        };
    },
    created() {
        this.loadHeader();
        this.loadFooter();
        this.loadSidenavigation();
    },

    methods: {
        loadFooter() {
            this.$apollo
                .query({
                    query: footer,
                })
                .then((response) => {
                    this.footer = response.data.getFooterListing.edges[0].node;
                });
        },
        loadHeader() {
            this.$apollo
                .query({
                    query: header,
                })
                .then((response) => {
                    this.header =
                        response.data.getNavigationListing.edges[0].node;
                });
        },
        loadSidenavigation() {
            this.$apollo
                .query({
                    query: sidenavigation,
                })
                .then((response) => {
                    this.sidebar =
                        response.data.getSidebarListing.edges[0].node;
                });
        },
    },
};
</script>

<style lang="scss">
@import "src/scss/bundle.scss";

.v-main {
    transform-origin: top;
}
</style>
