<template>
    <a
        :href="getLink()"
        target="_blank"
        class="image-box hover-background-color"
        v-ripple
        :data-id="data.id"
    >
        <custom-image
            v-if="data?.fullpath"
            :image="data"
            contain
            class="image"
            :hover="false"
        />
        <custom-image
            v-if="data?.images"
            :image="data.images[0]"
            contain
            class="image"
            :hover="false"
        />
        <custom-image
            v-if="data?.logo"
            :image="data.logo[0]"
            contain
            class="image"
            :hover="false"
        />
    </a>
</template>

<script setup>
import { defineProps } from "vue";
import CustomImage from "@/components/reuseables/customImage.vue";

const props = defineProps({
    data: Object,
});

function getLink() {
    if (props.data.links) {
        return props.data.links[0].link;
    }
    return "";
}
</script>

<style lang="scss" scoped>
a {
    display: block;
    padding: 2rem;
    color: white;
    .image {
        height: 4rem;
        width: 100%;
        background-size: contain;
    }
}
</style>
