<template>
    <div
        v-if="backgroundImage.image"
        class="background-image"
        :class="inverted ? 'inverted' : ''"
        :style="{
            backgroundImage:
                'url(' + api + backgroundImage.image.fullpath + ')',
        }"
    />
</template>

<script setup>
import { defineProps } from "vue";

defineProps({
    backgroundImage: {
        type: Object,
        required: true,
    },
    inverted: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const api = process.env.VUE_APP_API_URL;
</script>

<style lang="scss" scoped>
.background-image {
    position: absolute;
    z-index: 1 !important;
    background-repeat: repeat;
    background-size: auto;
    top: clamp(1rem, 2vw, 2rem);
    right: clamp(1rem, 2vw, 2rem);
    bottom: calc(clamp(1rem, 2vw, 2rem) * -1);
    left: calc(clamp(1rem, 2vw, 2rem) * -1);
    &.inverted {
        right: calc(clamp(1rem, 2vw, 2rem) * -1);
        left: clamp(1rem, 2vw, 2rem);
    }
}
</style>
