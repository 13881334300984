<template>
    <div class="mobile-navigation-button">
        <v-app-bar-nav-icon @click="drawer = !drawer" />

        <teleport to="body">
            <nav class="mobile-navigation" :class="{ show: drawer }">
                <div class="navigation_container">
                    <v-container>
                        <custom-button @click="drawer = false" class="w-100">
                            <v-icon icon="mdi-close" />
                        </custom-button>
                        <v-list density="compact" nav>
                            <template
                                v-for="(page, key) in header.pages"
                                :key="key"
                            >
                                <v-list-item
                                    v-if="page.element.children.length === 0"
                                    :to="'/' + page.element.url"
                                    class="font-weight-bold"
                                    @click="drawer = false"
                                >
                                    {{ page.element.urlText }}
                                </v-list-item>
                                <v-list-group v-else :value="page.element.url">
                                    <template v-slot:activator="{ props }">
                                        <v-list-item
                                            v-bind="props"
                                            class="font-weight-bold"
                                        >
                                            {{ page.element.urlText }}
                                        </v-list-item>
                                    </template>
                                    <v-list-item
                                        v-for="(children, k) in page.element
                                            .children"
                                        :key="k"
                                        :to="'/' + children.url"
                                        @click="drawer = false"
                                    >
                                        <v-list-item-title>
                                            {{ children.urlText }}
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list-group>
                                <hr />
                            </template>
                        </v-list>
                    </v-container>
                </div>
                <v-snackbar
                    v-model="snackbar"
                    timeout="-1"
                    attach="nav"
                    color="#e1e8d7"
                    class="ma-0"
                    rounded="0"
                >
                    <div class="button-snackbar" @click="drawer = false">
                        <navigation-header-desktop-additional-buttons
                            v-if="header.additionalButtons"
                            :additional-buttons="header.additionalButtons"
                        />
                    </div>
                </v-snackbar>
            </nav>
        </teleport>
    </div>
</template>

<script setup>
import CustomButton from "@/components/reuseables/customButton.vue";
import { defineProps, ref } from "vue";
import NavigationHeaderDesktopAdditionalButtons from "@/components/navigation/navigationHeader/navigationHeaderDesktopAdditionalButtons.vue";

defineProps({
    header: {
        type: Object,
        required: true,
    },
});

let drawer = ref(false);
const snackbar = ref(true);
</script>

<style lang="scss" scoped>
* {
    text-transform: lowercase;
    :deep(.v-list-item-title) {
        text-transform: lowercase;
    }
}

:deep(.button-snackbar) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    button,
    a {
        max-width: calc(100vw - 4rem);

        width: 100%;
    }
}

.mobile-navigation {
    bottom: 0;
    transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    will-change: transform;
    transform: translateY(110%);
    height: 100%;
    left: 0;
    z-index: 9999;
    position: fixed;
    width: 100%;
    background-color: white;

    &.show {
        transform: translateY(0);
    }
    .v-list {
        margin-top: 2rem;
        padding-bottom: 6rem;
        .v-list-item {
            margin: 0 !important;
            padding: 1rem 0;
            .v-list-item-title {
                font-size: 1rem;
                line-height: normal;
            }
        }
        .v-list-group__items {
            .v-list-item:last-child {
                margin-bottom: 2rem !important;
            }
        }
    }
    .navigation_container {
        flex: 0 1 auto;
        height: 100%;
        max-width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
    }
}
</style>
