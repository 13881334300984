<template>
    <div id="page-not-found">
        <span class="background">404</span>
        <div class="text">
            <h1>Error 404</h1>
            <h2>Diese Seite wurde nicht gefunden.</h2>
            <custom-button :to="'/'" class="mt-8">
                zurück zur Startseite</custom-button
            >
        </div>
    </div>
</template>

<script setup>
import { onMounted } from "vue";
import CustomButton from "@/components/reuseables/customButton.vue";
onMounted(() => {
    console.warn("404 - PAGE NOT FOUND");
});
</script>

<style lang="scss" scoped>
#page-not-found {
    min-height: 66vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    .background {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: clamp(15rem, 25vw, 30rem);
        color: rgba(0, 0, 0, 0.06);
        text-align: center;
        width: fit-content;
        font-weight: bold;
        pointer-events: none;
        z-index: 0;
    }
    .text {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
        z-index: 2;
    }
}
</style>
