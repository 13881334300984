<template>
    <div v-if="buttons" class="back-buttons spacing-bottom-xs">
        <template v-for="(button, l) in buttons" :key="l">
            <custom-button
                v-if="button.title"
                :to="button?.link || '/' + button?.dataObject?.url"
            >
                <img
                    class="custom-icon small ml-0 mr-2"
                    :src="api + '/frontend-icons/arrow-left.svg'"
                    alt="zurück"
                />
                {{ button.title }}
            </custom-button>
        </template>
    </div>
</template>

<script setup>
import CustomButton from "@/components/reuseables/customButton.vue";
import { defineProps } from "vue";

const api = process.env.VUE_APP_API_URL;

defineProps({
    buttons: {
        type: [Object, null],
        required: true,
    },
});
</script>

<style scoped lang="scss">
.back-buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    a,
    :deep(button) {
        width: fit-content;
        padding-left: 0;
    }
}
</style>
