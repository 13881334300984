<template>
    <v-form
        v-if="data.formSections && !finished"
        v-model="formValid"
        @submit.prevent="validate"
        class="w-100"
    >
        <section
            v-for="(section, sectionIndex) in data.formSections"
            :key="sectionIndex"
            :class="[
                'pt-' +
                    (section.paddingTop != null ? section.paddingTop : '12'),
                'pb-' +
                    (section.paddingBottom != null
                        ? section.paddingBottom
                        : '12'),
            ]"
        >
            <v-row no-gutters class="my-4">
                <v-col v-if="section.headline" class="v-col-12 text-center">
                    <headline-container mb="xs">
                        {{ section.headline }}
                    </headline-container>
                </v-col>
                <v-col v-if="section.text" class="v-col-12 text-center">
                    <p
                        class="my-5 color-primary font-weight-bold"
                        v-html="section.text"
                    />
                </v-col>
                <v-col
                    class="pa-2"
                    v-for="(field, index) in section.formFields"
                    :key="index"
                    :class="[
                        field.widthBase
                            ? 'v-col-' + field.widthBase
                            : 'v-col-12',
                        field.widthSM ? 'v-col-sm-' + field.widthSM : '',
                        field.widthMD ? 'v-col-md-' + field.widthMD : '',
                        field.widthLG ? 'v-col-lg-' + field.widthLG : '',
                        field.widthXL ? 'v-col-xl-' + field.widthXL : '',
                        field.widthXXL ? 'v-col-xxl-' + field.widthXXL : '',
                    ]"
                >
                    <component
                        :name="field.name"
                        :key="objectData"
                        :is="components[field.fieldType]"
                        :label="
                            field.mandatory ? field.label + '*' : field.label
                        "
                        :data="field"
                        :value="prefillData[field.name]"
                        @updated="updateFormData"
                        :options="getFieldOptions(field.fieldOptions)"
                    />
                </v-col>
            </v-row>
        </section>
        <v-col cols="12" class="d-flex align-content-center">
            <custom-button
                type="submit"
                color="black"
                bg-color="#b9c29a"
                :style="data.submitButtonStyling"
                :class="data.submitButtonClasses"
            >
                {{ data.submitText }}
            </custom-button>
        </v-col>
    </v-form>
    <form-renderer-finished v-if="finished" :text="data.successMessage" />
</template>

<script setup>
import Select from "@/components/form/fields/fieldSelect.vue";
import Input from "@/components/form/fields/fieldInput.vue";
import Checkbox from "@/components/form/fields/fieldCheckbox.vue";
import Textarea from "@/components/form/fields/fieldTextarea.vue";
import Radiobutton from "@/components/form/fields/fieldRadiobutton.vue";
import { ref, defineEmits, defineProps } from "vue";
import { apolloProvider } from "@/plugins/apollo";
import { v4 as uuidv4 } from "uuid";
import CustomButton from "@/components/reuseables/customButton.vue";
import createRequestContactById from "@/graphql/mutation/createRequestContactById.graphql";
import FormRendererFinished from "@/components/form/formRendererFinished.vue";
import HeadlineContainer from "@/components/reuseables/headlineContainer.vue";

const mutations = {
        createRequestContactById,
    },
    emit = defineEmits([
        "customAction",
        "updatedData",
        "createdData",
        "formData",
    ]);

const formData = ref({}),
    formValid = ref(null),
    prefillData = ref({}),
    objectData = ref({}),
    finished = ref(false),
    components = {
        Select,
        Input,
        Checkbox,
        Textarea,
        Radiobutton,
    };

const props = defineProps({
    data: {
        type: Object,
        required: false,
    },
});

function getFieldOptions(data) {
    let array = [];
    if (data) {
        data.forEach((item) => {
            array.push({ key: item.optionKey, val: item.optionValue });
        });
    }
    return array;
}

function validate() {
    if (formValid.value) {
        submit();
    } else {
        console.error("Form validation failed");
    }
}

function checkInput(input) {
    if (input === "true") {
        input = true;
    }
    if (input === "false") {
        input = false;
    }
    return input;
}

function updateFormData(fieldName, value) {
    if (value) {
        if (value.key) {
            formData.value[fieldName] = checkInput(value.key);
        } else {
            formData.value[fieldName] = checkInput(value.val);
        }
        emit("formData", formData.value);
    }
}

function submit() {
    localStorage.setItem("golfclub-form-" + props.data.id, "true");
    // check form data ("true" => true)
    for (let field in formData.value) {
        formData.value[field] = checkInput(formData.value[field]);
    }

    // execute custom action in the parent component
    if (props.customAction) {
        emit("customAction", formData.value);
        return;
    }

    // submitting form data
    const mutationName =
        props.data.mutationType + props.data.mutationClass + props.data.by;

    create(
        mutationName,
        props.data.destination,
        uuidv4().toString(),
        formData.value
    );
}

function create(mutationName, destination, key, input) {
    let variables = {
        key: key,
        input: input,
    };

    if (typeof destination === "number" || parseInt(destination)) {
        variables["parentId"] = parseInt(destination);
    } else {
        variables["path"] = destination;
    }

    apolloProvider.defaultClient
        .mutate({
            mutation: mutations[mutationName],
            variables: variables,
        })
        .then(() => {
            finished.value = true;
            console.log("form send");
        });
}
</script>
