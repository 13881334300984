<template>
    <field-headline
        :label="label"
        :additionalLabel="data.additionalLabel"
        :annotation="annotation"
    />

    <v-radio-group
        v-model="input.key"
        @update:model-value="
            emit('updated', props.name, { key: input.key, val: '' })
        "
        :rules="checkFormFieldRules(data)"
    >
        <v-radio
            v-for="(button, key) in data.fieldOptions"
            :label="button.optionValue"
            :value="button.optionKey"
            :key="key"
        />
    </v-radio-group>
</template>

<script setup>
import { onMounted, ref, defineProps, defineEmits } from "vue";
import FieldHeadline from "@/components/form/fields/fieldHeadline.vue";
import { checkFormFieldRules } from "@/plugins/form/validation";

const emit = defineEmits(["updated"]),
    input = ref({ key: "", val: "" }),
    props = defineProps({
        name: {
            type: String,
            required: false,
        },
        data: {
            type: Object,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        value: {
            type: Object,
            required: false,
            default() {
                return {
                    key: "",
                    val: false,
                };
            },
        },
        options: {
            type: Array,
            required: false,
        },
        annotation: {
            type: Array,
            required: false,
        },
    });

onMounted(() => {
    input.value = props.value;
});
</script>

<style lang="scss" scoped>
:deep(i) {
    color: var(--color-primary);
}

:deep(label) {
    color: var(--color-primary);
    opacity: 1;
}
</style>
