<template>
    <table class="display-list">
        <thead>
            <tr>
                <th class="text-left">Kurs<wbr />bezeich<wbr />nung</th>
                <th class="text-right">Datum</th>
                <th class="text-right">Uhrzeit</th>
            </tr>
        </thead>
        <tbody>
            <template v-for="(year, y) in dates" :key="y">
                <template v-for="(month, m) in year" :key="y + m">
                    <template v-for="(group, g) in month" :key="g">
                        <tr v-for="(lesson, l) in group.lessons" :key="l">
                            <td>{{ group.title }}</td>
                            <td class="text-right">
                                {{ getWeekday(lesson.date) }} -
                                {{ lesson.date }}
                            </td>
                            <td class="text-right">
                                {{ lesson.start }}&nbsp;bis
                                {{ lesson.end }}&nbsp;Uhr
                            </td>
                        </tr>
                    </template>
                </template>
            </template>
        </tbody>
    </table>
</template>

<script setup>
import { defineProps } from "vue";
import { weekdays, weekdaysSmall } from "@/mixins/global/globalData";

defineProps({
    dates: {
        type: Object,
        required: true,
    },
});

function getWeekday(date) {
    const splittedDate = date.split("."),
        year = splittedDate[2],
        month = splittedDate[1],
        day = splittedDate[0];

    if (window.innerWidth > 720)
        return weekdays[new Date(`${month}/${day}/${year}`).getDay()];
    else if (window.innerWidth <= 720)
        return weekdaysSmall[new Date(`${month}/${day}/${year}`).getDay()];
}
</script>

<style lang="scss" scoped>
table.display-list {
    width: 100%;
    border-spacing: 0;
    margin-top: 4rem;
    @media (max-width: 959px) {
        margin-bottom: 4rem;
    }
    tr {
        th,
        td {
            padding: 0.5rem 0 !important;
            font-size: 16px;
            border-top: 2px solid black;
            &:nth-child(2) {
                padding: 1rem !important;
            }
        }
        th {
            color: black;
        }
        td {
            word-break: keep-all;
        }
        &:last-child {
            td {
                border-bottom: 2px solid black;
            }
        }
    }
}

.lesson {
    display: flex;
    justify-content: space-between;
}
</style>
