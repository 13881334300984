<template>
    <div class="swiper navigation-dates spacing-bottom-xs">
        <div class="swiper-wrapper">
            <div
                v-for="(date, key) in getDates()"
                class="swiper-slide date-slide"
                :key="'date-' + key"
            >
                <button
                    :class="{ active: date.text === activeDate }"
                    :data-value="date.value"
                    @click="setActiveDate(date.text, date.value)"
                >
                    <span v-html="date.text" />
                </button>
            </div>
        </div>

        <div class="swiper-button-prev top">
            <img
                class="custom-icon"
                :src="api + '/frontend-icons/arrow-left.svg'"
                alt="weiter"
            />
        </div>
        <div class="swiper-button-next top">
            <img
                class="custom-icon"
                :src="api + '/frontend-icons/arrow-right.svg'"
                alt="weiter"
            />
        </div>
    </div>
</template>

<script setup>
import { defineEmits, onMounted, ref } from "vue";
import Swiper from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { getWeekday } from "@/mixins/global/globalData";

const activeDate = ref(0),
    api = process.env.VUE_APP_API_URL,
    swiper = ref(),
    datesArray = ref(),
    emit = defineEmits(["dateUpdate"]);

function formatDate(date) {
    const currentWeekday = getWeekday([date.getDay()]),
        currentDay = date.getDate(),
        currentMonth = date.getMonth() + 1;

    return `${currentWeekday}, ${
        currentDay < 10 ? "0" : ""
    }${currentDay}.&nbsp;${currentMonth < 10 ? "0" : ""}${currentMonth}.`;
}

function getDates() {
    const today = new Date();
    const dates = [];

    for (let i = 0; i < 7; i++) {
        const newDate = new Date(today);
        newDate.setDate(today.getDate() + i);

        dates.push({
            text: formatDate(newDate),
            value: newDate.toISOString().split("T")[0],
        });
    }

    datesArray.value = dates;

    return dates;
}

function setActiveDate(date, value) {
    activeDate.value = date;
    emit("dateUpdate", value);
}

async function createSwiper() {
    swiper.value = await new Swiper(".swiper.navigation-dates", {
        modules: [Navigation],
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        slidesPerView: 1,
        breakpoints: {
            1920: {
                slidesPerView: 7,
            },
        },
    });
    swiper.value.on("slideChange", function (swiper) {
        const newDate = datesArray.value[swiper.activeIndex];
        setActiveDate(newDate.text, newDate.value);
    });
}

onMounted(() => {
    activeDate.value = formatDate(new Date());
    createSwiper();
    emit("dateUpdate", datesArray.value[0]);
});
</script>

<style scoped lang="scss">
.swiper {
    border: 2px solid black;
    border-left: none;
    border-right: none;
    .swiper-wrapper {
        align-items: center;
    }

    button {
        width: 100%;
        height: 100%;
        padding: 0.5rem 0.125rem;
        transition: font-weight 0.33s ease, font-size 0.33s ease;
        line-height: 2.5rem;
        color: var(--deactivate-color);
        font-weight: bold;
        font-size: 14px;
        min-height: 30px;
        text-transform: uppercase;

        &.active {
            color: black;
            font-size: 24px;
        }
        @media (max-width: 1919px) {
            font-size: 24px;
        }
    }
}

.date-slide {
    display: flex;
    justify-content: center;
    @media (max-width: 1919px) {
        background-color: var(--light-color);
    }
    @media (min-width: 1920px) {
        width: auto !important;
        flex-grow: 1;
    }
    button {
        line-height: 1.2;
        @media (max-width: 1919px) {
            max-width: calc(100% - 6rem);
        }
    }
}

.swiper-button-next,
.swiper-button-prev {
    height: 100%;
    top: 0;
    background-color: unset;
    &:after {
        content: "";
    }
}
</style>
