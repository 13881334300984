<template>
    <v-expansion-panels v-for="(year, key) in dates" :key="key" multiple>
        <v-expansion-panel elevation="0" v-for="(month, m) in year" :key="m">
            <v-expansion-panel-title expand-icon="mdi-menu-down">
                <template v-slot:default="">
                    <span class="font-weight-bold"> {{ m }} - {{ key }} </span>
                </template>

                <template v-slot:actions="{ expanded }">
                    <v-img
                        :src="
                            expanded
                                ? api + '/frontend-icons/dash.svg'
                                : api + '/frontend-icons/plus-lg.svg'
                        "
                        width="1.5rem"
                    />
                </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
                <v-row>
                    <v-col
                        cols="12"
                        sm="6"
                        md="12"
                        lg="6"
                        v-for="(groups, g) in month"
                        :key="g"
                    >
                        <div class="headline">
                            <span class="font-weight-bold"
                                >{{ groups.title }}
                            </span>
                        </div>
                        <div
                            v-for="(lesson, lessonKey) in groups.lessons"
                            :key="lessonKey"
                            class="information"
                        >
                            <span class="date">
                                {{ getWeekday(lesson.date) }},
                                {{ getDay(lesson.date) }}
                            </span>
                            <span class="time">
                                {{ lesson.start }} bis {{ lesson.end }} Uhr
                            </span>
                        </div>
                    </v-col>
                </v-row>
            </v-expansion-panel-text>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script setup>
import { defineProps } from "vue";
import { weekdays, weekdaysSmall } from "@/mixins/global/globalData";

const api = process.env.VUE_APP_API_URL;

defineProps({
    dates: {
        type: Object,
        required: true,
    },
});

function getWeekday(date) {
    const splittedDate = date.split("."),
        year = splittedDate[2],
        month = splittedDate[1],
        day = splittedDate[0];

    if (window.innerWidth > 720)
        return weekdays[new Date(`${month}/${day}/${year}`).getDay()];
    else if (window.innerWidth <= 720)
        return weekdaysSmall[new Date(`${month}/${day}/${year}`).getDay()];
}

function getDay(date) {
    const splittedDate = date.split(".");
    return splittedDate[0] + "." + splittedDate[1] + ".";
}
</script>

<style lang="scss" scoped>
.v-expansion-panels {
    margin-top: 2rem;
    .v-expansion-panel {
        margin: 0.75rem 0;

        &.v-expansion-panel--active {
            margin-bottom: 2rem;
        }

        button.v-expansion-panel-title {
            border: 2px solid black;
            border-left: none;
            border-right: none;
            border-radius: 0;
            min-height: auto;
            padding: 1rem 0;
            font-size: 18px;
            :deep(.v-expansion-panel-title__overlay) {
                display: none;
            }
        }
        :deep(.v-expansion-panel-text) {
            .v-expansion-panel-text__wrapper {
                padding: 0;
                border-bottom: 2px solid black;

                .v-row {
                    margin: 0;
                    .v-col-12 {
                        padding: 0;
                        .headline {
                            background-color: var(--light-color);
                            padding: 0.5rem 0;
                            font-size: 18px;
                            border: 2px solid black;
                            border-left: none;
                            border-right: none;
                        }
                        .information {
                            margin: 0.25rem 0;
                            font-size: 16px;
                            display: flex;
                            justify-content: space-between;
                            &:last-child {
                                margin-bottom: 1rem;
                            }
                        }

                        &:nth-child(2n - 1) {
                            @media (min-width: 600px) {
                                .headline,
                                .information {
                                    padding-right: 12px;
                                }
                            }
                        }

                        &:nth-child(2n) {
                            @media (min-width: 600px) {
                                .headline,
                                .information {
                                    padding-left: 12px;
                                }
                            }
                            @media (min-width: 960px) and (max-width: 1279px) {
                                .headline,
                                .information {
                                    padding-left: 0;
                                }
                            }
                        }

                        &:nth-child(1) {
                            .headline {
                                border-top: none;
                            }
                        }
                        &:nth-child(2) {
                            @media (min-width: 600px) and (max-width: 959px) {
                                .headline {
                                    border-top: none;
                                }
                            }
                            @media (min-width: 1280px) {
                                .headline {
                                    border-top: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
