<template>
    <headline-container mt="md" mb="xs"> AUCH INTERESSANT </headline-container>
    <v-row class="mb-16">
        <v-col v-for="(news, n) in newsArray" :key="n" v-bind="cols">
            <router-link
                :to="'/news/' + news.node.slug"
                v-ripple
                height="100%"
                width="100%"
            >
                <v-row>
                    <v-col cols="12" md="6">
                        <custom-image
                            v-if="news.node.imageTeaser"
                            class="image"
                            :image="news.node.imageTeaser[0]"
                            cover
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <div class="text-container">
                            <span class="date" v-if="news.node.date">
                                {{ news.node.date }}
                            </span>
                            <h3 v-if="news.node.texts">
                                {{ news.node.texts[0].headline }}
                            </h3>
                            <custom-button class="read-more" color="black">
                                Alles Lesen
                                <img
                                    class="custom-icon"
                                    :src="
                                        api + '/frontend-icons/arrow-right.svg'
                                    "
                                    alt="weiter"
                                />
                            </custom-button>
                        </div>
                    </v-col>
                </v-row>
            </router-link>
        </v-col>
    </v-row>
</template>

<script setup>
import { ref, onMounted } from "vue";
import CustomButton from "@/components/reuseables/customButton.vue";
import CustomImage from "@/components/reuseables/customImage.vue";
import HeadlineContainer from "@/components/reuseables/headlineContainer.vue";
import { apolloProvider } from "@/plugins/apollo";
import newsListing from "@/graphql/querie/listing/newsListing.graphql";

const api = process.env.VUE_APP_API_URL,
    cols = {
        cols: 12,
        offsetSm: 1,
        sm: 10,
        offsetMd: 1,
        md: 10,
        offsetLg: 0,
        lg: 6,
    },
    newsArray = ref({});

function loadNewsListing() {
    apolloProvider.defaultClient
        .query({
            fetchPolicy: "no-cache",
            query: newsListing,
            variables: {
                first: 2,
            },
        })
        .then((response) => {
            newsArray.value = response.data.getNewsListing.edges;
        });
}

onMounted(() => {
    loadNewsListing();
});
</script>

<style lang="scss" scoped>
// TODO:
// TOGETHER WITH SAME COMPONENTS
.v-container {
    margin-bottom: 5rem;
}

a {
    color: white;
    text-decoration: none;
}

.headline-container {
    h2 {
        font-size: 1rem;
        font-weight: bold;
    }
}
.image-container {
    height: 12rem !important;
    max-height: 50vh;
}

.text-container {
    height: 100%;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    padding: 0.75rem 0;
    position: relative;
    text-decoration: none;
    color: black;
    @media (max-width: 960px) {
        border-top: none;
        padding-top: 0;
    }
    * {
        text-decoration: none;
    }

    .date {
        display: block;
        margin-bottom: 0.5rem;
    }
    h3 {
        font-size: 18px;
        line-height: 24px;
    }
    .read-more {
        position: absolute;
        bottom: 0;
        right: 0;
        height: auto;
        padding: 1rem;
        @media (max-width: 960px) {
            position: relative;
            float: right;
            margin: 1rem 0;
        }
    }
}

a {
    display: block;
    padding: 0;
    :deep(.v-btn__content) {
        width: 100%;
    }
}
</style>
