<template>
    <swiper
        :breakpoints="{
            '@0.00': {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            '@0.75': {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            '@1.00': {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            '@1.50': {
                slidesPerView: 4,
                spaceBetween: 20,
            },
        }"
        :modules="modules"
        :navigation="true"
        :pagination="{ enabled: true }"
        :slidesPerView="6"
        :spaceBetween="20"
        @afterInit="swiperLoaded = true"
    >
        <swiper-slide v-for="(item, i) in data" :key="i">
            <custom-image
                v-if="swiperLoaded && item?.image && item?.image?.fullpath"
                :console-log="true"
                :image="item.image"
                cover
                height="100%"
                @click="showSingle(i)"
            />
        </swiper-slide>
    </swiper>
    <teleport to="body">
        <vue-easy-lightbox
            :visible="visibleRef"
            :imgs="getImagesForLightBox(data)"
            :index="indexRef"
            @hide="onHide"
            rotate-disabled
            zoom-disabled
            pinch-disabled
            move-disabled
        />
    </teleport>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import { ref } from "vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Navigation, Pagination } from "swiper/modules";
import CustomImage from "@/components/reuseables/customImage.vue";
import VueEasyLightbox from "vue-easy-lightbox";
import { getImagesForLightBox } from "@/mixins/global/globalMethods";

const visibleRef = ref(false),
    indexRef = ref(0),
    swiperLoaded = ref(false);

const showSingle = (index) => {
        indexRef.value = index;
        visibleRef.value = true;
    },
    onHide = () => (visibleRef.value = false);

export default {
    methods: {
        getImagesForLightBox,
    },
    components: {
        VueEasyLightbox,
        CustomImage,
        Swiper,
        SwiperSlide,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },

    setup() {
        return {
            modules: [Navigation, Pagination],
            visibleRef,
            indexRef,
            showSingle,
            onHide,
            swiperLoaded,
        };
    },
};
</script>

<style lang="scss" scoped>
.swiper-button-prev,
.swiper-button-next {
    color: white;
}

.swiper-slide {
    height: 420px;
}

.swiper {
    padding-bottom: 3rem;
    :deep(.swiper-pagination) {
        .swiper-pagination-bullet {
            &.swiper-pagination-bullet-active {
                background-color: var(--true-color) !important;
            }
        }
    }

    :deep(.swiper-button-next), :deep(.swiper-button-prev) {
        height: 2rem;
        width: 2rem;
        border-radius: 1rem;
        background-color: white;
        top: 50%;
        transform: translateY(-50%);
        box-shadow: rgba(0, 0, 0, 0.1) 0 4px 6px -1px, rgba(0, 0, 0, 0.06) 0 2px 4px -1px;
        &:after {
            font-size: 1rem;
        }
    }
}
</style>
