<template>
    <v-row>
        <div
            v-for="(image, k) in data.images"
            :key="'gallery-key-' + k"
            class="gallery-col"
            :style="{ width: width + '%' }"
        >
            <custom-image
                :image="image"
                :contain="true"
                height="auto"
                width="auto"
                @click="(visibleRef = !visibleRef), (indexRef = 1)"
            />
        </div>
    </v-row>
    <vue-easy-lightbox
        v-if="data.images"
        :visible="visibleRef"
        :imgs="imgsRef"
        :index="indexRef"
        @hide="onHide"
        rotate-disabled
        zoom-disabled
        pinch-disabled
        move-disabled
    />
</template>

<script setup>
import { computed, defineProps } from "vue";
import CustomImage from "@/components/reuseables/customImage.vue";
import VueEasyLightbox, { useEasyLightbox } from "vue-easy-lightbox";
import { getImagesForLightBox } from "@/mixins/global/globalMethods";

const width = computed(() => {
        let windowWidth = window.innerWidth;
        if (windowWidth < 600) {
            return 100 / props.data.amountOfRowsBase;
        }
        if (windowWidth < 960) {
            return 100 / props.data.amountOfRowsSM;
        }
        if (windowWidth < 1264) {
            return 100 / props.data.amountOfRowsMD;
        }
        if (windowWidth < 1904) {
            return 100 / props.data.amountOfRowsLG;
        }
        if (windowWidth >= 1904) {
            return 100 / props.data.amountOfRowsXL;
        }
        return 1;
    }),
    props = defineProps({
        data: Object,
    });

const {
    // methods
    onHide,
    // refs
    visibleRef,
    indexRef,
    imgsRef,
} = useEasyLightbox({
    imgs: getImagesForLightBox(props.data?.images),
    // initial index
    initIndex: 0,
});
</script>

<style lang="scss" scoped>
.gallery-col {
    padding: 12px;
}
</style>
