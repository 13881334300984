<template>
    <div :style="{ height: height, width: width }" class="loading-circle">
        <v-progress-circular
            :size="50"
            aria-label="Ladebalken"
            color="ä33c1ba"
            indeterminate
        >
        </v-progress-circular>
    </div>
</template>

<script>
export default {
    name: "customLoading",
    props: {
        height: {
            type: String,
            required: false,
            default: "66vh",
        },
        width: {
            type: String,
            required: false,
            default: "100%",
        },
    },
};
</script>

<style lang="scss" scoped>
.loading-circle {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
