<template>
    <h2 v-if="data.headline" class="mb-4">{{ data.headline }}</h2>

    <div class="table-wrapper" v-if="table">
        <table>
            <thead>
                <tr>
                    <th v-for="(head, k) in table[0]" :key="'head-' + k">
                        {{ head }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(row, j) in table" :key="'row-' + j">
                    <tr v-if="j > 0">
                        <td v-for="(body, k) in row" :key="'body- ' + k">
                            {{ body }}
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script setup>
import { defineProps, onMounted, ref } from "vue";

const props = defineProps({
    data: {
        type: Object,
        required: true,
    },
});

const table = ref({});
function stringToMultiArray(input) {
    const slicedString = input.slice(2, -2),
        tableArray = slicedString.split("],[");

    tableArray.forEach((lineString, k) => {
        tableArray[k] = lineString.replaceAll('"', "").split(",");
    });

    table.value = tableArray;
}

onMounted(() => {
    stringToMultiArray(props.data.table);
});
</script>

<style lang="scss" scoped>
.table-wrapper {
    max-width: 100%;
    overflow-y: auto;
}
table {
    width: 100%;
    border-spacing: unset;
    min-width: 640px;
    thead {
        tr {
            th {
                border-top: 2px solid black;
                border-bottom: 2px solid black;
                background-color: #d2d7c0;
            }
        }
    }
    tbody {
        tr {
            td {
            }
            &:nth-child(2n) td {
                background-color: #fafafa;
            }
        }
    }
    tr {
        th,
        td {
            text-align: left;
            padding: 1rem;
        }
    }
}
</style>
