<template>
    <headline-container v-if="data.headline" mb="xs">
        {{ data.headline }}
    </headline-container>
    <v-row>
        <v-col
            v-for="(col, k) in data.cols"
            :key="'col-' + k"
            v-bind="getColumnProps(col)"
        >
            <component :is="components[col.item.__typename]" :data="col.item" />
        </v-col>
    </v-row>
</template>

<script setup>
import { defineProps } from "vue";
import object_teaser from "@/components/object_Teaser.vue";
import object_objectListing from "@/components/object_objectListing.vue";
import object_images from "@/components/object_images.vue";
import HeadlineContainer from "@/components/reuseables/headlineContainer.vue";
import object_contactWrapper from "@/components/object_contactWrapper.vue";

defineProps({
    data: Object,
});

const components = {
    object_teaser,
    object_objectListing,
    object_images,
    object_contactWrapper,
};

function getColumnProps(col) {
    const data = {}
    if (col.spanBase) data.cols = col.spanBase;
    if (col.offsetBase) data.offset = col.offsetBase;
    if (col.spanSM) data.sm = col.spanSM;
    if (col.offsetSM) data.offsetSm = col.offsetSM;
    if (col.spanMD) data.md = col.spanMD;
    if (col.offsetMD) data.offsetMd = col.offsetMD;
    if (col.spanLG) data.lg = col.spanLG;
    if (col.offsetXL) data.offsetXl = col.offsetXL;
    if (col.spanXL) data.xl = col.spanXL;
    return data;
}
</script>
